import React from 'react';
import classnames from 'classnames';

export default function CardWrapper({
  children, className, containerClassName, title, extra, header, footer, style,
}) {
  function renderHeader() {
    const containerStyle = 'tw-px-small tw-py-small tw-w-full border-b tw-flex tw-justify-between';

    if (header) {
      return (
        <div className={containerStyle}>
          {header}
        </div>
      );
    }

    if (title) {
      return (
        <div className={containerStyle}>
          <h6 className="tw-mb-none">{title}</h6>
          {extra && <div className="tw-flex-none">{extra}</div>}
        </div>
      );
    }

    return null;
  }

  return (
    <div className={classnames('tw-rounded-2xl tw-bg-background-inkWhite-white_0 tw-border-solid border', containerClassName)}>
      {renderHeader()}
      <div className={classnames('tw-p-small', className)} style={style}>
        {children}
      </div>
      {footer && (
        <div className="tw-px-small tw-py-small tw-w-full border-t">
          {footer}
        </div>
      )}
    </div>
  );
}
