import React, { memo, useMemo, useState } from 'react';
import { Image, Upload } from 'antd';
import { collect } from 'collect.js';
import { PlusOutlined } from '@ant-design/icons';
import ImageCropper from 'antd-img-crop';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const FileAction = {
  add: 'add',
  remove: 'remove',
};

const getBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

function DraggableUploadListItem({ originNode, file }) {
  const {
    attributes, listeners, setNodeRef, transform, transition, isDragging,
  } = useSortable({
    id: file.uid,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'move',
    height: '100%',
    width: '100%',
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={isDragging ? 'is-dragging' : ''}
      {...attributes}
      {...listeners}
    >
      {file.status === 'error' && isDragging ? originNode.props.children : originNode}
    </div>
  );
}

export default function ImagePicker({
  images,
  placeholder,
  onChange,
  onAdd,
  onDelete,
  onReorder,
  withDnd,
  withCropper,
  maxCount = 1,
  format = 'image/png',
  loadingImageId = null,
}) {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });

  const files = useMemo(
    () => collect(images)
      .filter((item) => Boolean(item))
      .map((item, index) => ({
        uid: `image-${index}`,
        status: 'done',
        file: item,
        url: item instanceof File ? URL.createObjectURL(item) : item,
      })).toArray(),
    [images, loadingImageId],
  );

  const handleChange = (file, action) => {
    if (action === FileAction.remove) {
      onChange(
        collect(files)
          .where('uid', '!=', file.uid)
          .pluck('file')
          .toArray(),
      );

      return onDelete && onDelete(file);
    }

    onChange(
      collect(files)
        .pluck('file')
        .push(file)
        .toArray(),
    );

    return onAdd && onAdd(file);
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id === over?.id) {
      return;
    }

    const activeIndex = files.findIndex((i) => i.uid === active.id);
    const overIndex = files.findIndex((i) => i.uid === over?.id);
    const result = collect(arrayMove(files, activeIndex, overIndex))
      .pluck('file')
      .toArray();

    onChange(result);

    return onReorder && onReorder(result);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || (file.preview));
    setPreviewOpen(true);
  };

  const Wrapper = memo(
    ({ withDnd, withCropper, children }) => (
      // eslint-disable-next-line no-nested-ternary
      withDnd ? (
        <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
          <SortableContext items={files.map((i) => i.uid)} strategy={horizontalListSortingStrategy}>
            {withCropper
              ? <ImageCropper rotationSlider>{children}</ImageCropper>
              : children}
          </SortableContext>
        </DndContext>
      ) : (
        withCropper
          ? <ImageCropper rotationSlider>{children}</ImageCropper>
          : children
      )
    ),
  );

  return (
    <Wrapper withDnd={withDnd} withCropper={withCropper}>
      <Upload
        listType="picture-card"
        fileList={files}
        onPreview={handlePreview}
        beforeUpload={(file) => { handleChange(file, FileAction.add); return false; }}
        onRemove={(file) => handleChange(file, FileAction.remove)}
        maxCount={maxCount}
        accept={format}
        itemRender={(originNode, file) => (
          <DraggableUploadListItem originNode={originNode} file={file} />
        )}
      >
        {files.length >= maxCount ? null : (
          <button style={{ border: 0, background: 'none' }} type="button">
            {placeholder ? <img src={placeholder} className="tw-max-w-[100%]" /> : (
              <>
                <PlusOutlined />
                <div className="tw-mt-mini">Upload</div>
              </>
            )}
          </button>
        )}
      </Upload>
      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </Wrapper>
  );
}
