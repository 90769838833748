import { ORDER_METHODS, PAYMENT_METHODS } from '../../../../../../services/exports/Constants';

export default {
  title: 'Zahlungsarten',
  fields: {
    hidden_payment_methods: {
      label: 'Versteckte Zahlungsmöglichkeiten',
    },
    has_online_payments: {
      label: 'Hat Online-Zahlungen',
    },
    has_paypal_upcharge: {
      label: 'Aufpreis 3,5% extra für Paypal-Transaktionen',
    },
    has_cash_payments: {
      label: 'Hat Barzahlungen',
    },
    has_in_store_card_payments: {
      label: 'Hat Kartenzahlungen in Geschäften',
    },
    overwrite_cash_payments_to_invoice: {
      label: 'Barzahlungen als "Rechnung" kennzeichnen',
    },
  },
  payment_methods: {
    [PAYMENT_METHODS.CARD]: 'Card',
    [PAYMENT_METHODS.PAYPAL]: 'PayPal',
    [PAYMENT_METHODS.APPLE_PAY]: 'Apple pay',
    [PAYMENT_METHODS.GOOGLE_PAY]: 'GPay',
    [PAYMENT_METHODS.SOFORT]: 'Sofort',
    [PAYMENT_METHODS.GIROPAY]: 'Giropay',
    [PAYMENT_METHODS.SEPA_DEBIT]: 'Sepa debit',
    [PAYMENT_METHODS.CASH]: 'Cash',
    [PAYMENT_METHODS.BACS_DEBIT]: 'Bacs debit',
  },
  order_methods: {
    [ORDER_METHODS.pickup]: 'Abholung',
    [ORDER_METHODS.delivery]: 'Lieferung',
    [ORDER_METHODS.room_service]: 'Zimmer/Tisch-service',
  },
  modals: {
    confirmation: {
      title: 'Sind Sie sicher?',
      description: 'Nach der Bestätigung werden die Online-Zahlungen <strong>{{onlineState}}</strong>, und die Barzahlungen <strong>{{cashState}}</strong>',
    },
  },
  labels: {
    off: 'ausgeschaltet',
    on: 'eingeschaltet',
  },
  buttons: {
    save: 'Speichern',
  },
  toasts: {
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
    changes_got_saved: 'Änderungen wurden gespeichert',
  },
};
