import { useEffect } from 'react';
import usePermissions from '../../hooks/auth/usePermissions';
import useAuth from '../../hooks/auth/useAuth';
import ReduxHooks from '../../store/ReduxHooks';
import { actionCreators } from '../../store/actions';

export default function OCCAgentGate({ children }) {
  const { isLoggedIn } = useAuth();
  const { roles, isAdmin, isOccAgent } = usePermissions();
  const hasAccess = !isOccAgent || isAdmin;

  useEffect(() => {
    if (isLoggedIn && !hasAccess) {
      ReduxHooks.dispatch(actionCreators.initialData.resetProfile());

      window.location = import.meta.env.VITE_OCC_PORTAL_URL;
    }
  }, [isLoggedIn, hasAccess]);

  return (!isLoggedIn || hasAccess) ? children : null;
}
