import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import {
  CBadge,
} from '@coreui/react';
import classnames from 'classnames';
import CIcon from '@coreui/icons-react';
import { useSelector } from 'react-redux';
import { CloseOutlined, EditOutlined, UndoOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import useHelpers from '../../hooks/context/useHelpers';
import { PERMISSIONS, TAX_BEHAVIOUR } from '../../services/exports/Constants';
import TaxCalculator from '../../services/helpers/TaxCalculator';
import usePermissions from '../../hooks/auth/usePermissions';
import useForm from '../../hooks/utility/useForm';
import PriceEditor from '../subscription/PriceEditor';
import PricingTiers from '../subscription/PricingTiers';

export const VARIANTS = {
  primary: 'primary',
  secondary: 'secondary',
};

export default function SubscriptionProduct({
  data,
  lineItem,
  prevState,
  variant = VARIANTS.primary,
  onAdd,
  onUpdate,
  onRemove,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Billing:SubscriptionProduct' });

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);

  const { can } = usePermissions();
  const { formatCurrency } = useHelpers();

  const [isEditing, setIsEditing] = useState(false);
  const { data: localData, update } = useForm({
    ...data.price,
    id: null,
    tax_behaviour: TAX_BEHAVIOUR.exclusive,
    amount: data.price.amount,
    tiers: data.price.tiers ?? [],
    has_pricing_tiers: data.price.tiers.length > 0,
  });

  const quantity = lineItem?.quantity ?? 0;
  const isPrimary = variant === VARIANTS.primary;
  const isSecondary = variant === VARIANTS.secondary;
  const isAdded = quantity > 0;

  const price = lineItem?.price ?? data.price;
  const { amount } = price;

  const _onUpdate = () => {
    const taxCalculator = TaxCalculator.make(localData.tax_behaviour);

    onUpdate({
      price: {
        ...localData,
        tax: taxCalculator.tax(localData.amount, price.tax_percentage),
        taxed_amount: taxCalculator.brutto(localData.amount, price.tax_percentage),
        tiers: localData.has_pricing_tiers ? localData.tiers : [],
      },
    });
    setIsEditing(false);
  };

  const undoChanges = () => {
    onUpdate(prevState);
    update('has_pricing_tiers', prevState.price.tiers.length > 0);
  };

  const renderTax = () => {
    const taxCalculator = TaxCalculator.make(price.tax_behaviour);

    if (price.tax_behaviour === TAX_BEHAVIOUR.inclusive) {
      return t('tax.inclusive', {
        taxName: serviceProvider.regional_config.tax_name,
        tax: formatCurrency(taxCalculator.tax(amount, price.tax_percentage), price.currency),
      });
    }

    return t('tax.exclusive', {
      taxName: serviceProvider.regional_config.tax_name,
      tax: formatCurrency(taxCalculator.tax(amount, price.tax_percentage), price.currency),
    });
  };

  const renderEditContent = () => isEditing && (
    <div className="tw-rotate-y-180">
      <Button
        icon={<CloseOutlined />}
        onClick={() => setIsEditing(false)}
        className="tw-absolute tw-right-0 tw-top-0"
      />
      <h5>{data.name}</h5>
      {data?.description && (
        <p className="mb-0 whitespace-pre-line">{data?.description}</p>
      )}
      <PriceEditor
        data={localData}
        disableTiers={variant === VARIANTS.secondary}
        update={update}
      />
      <Button
        type="primary"
        size="large"
        className="tw-mt-small"
        onClick={_onUpdate}
      >
        {t('buttons.save')}
      </Button>
    </div>
  );

  const renderPreviewContent = () => !isEditing && (
    <>
      {can(PERMISSIONS.edit_subscription_prices) && (
        <div className="tw-absolute tw-right-4 tw-top-4 tw-flex">
          {!data.price.id && (
            <Button
              icon={<UndoOutlined />}
              onClick={undoChanges}
              className="mr-2"
            />
          )}
          <Button
            icon={<EditOutlined />}
            onClick={() => setIsEditing(true)}
          />
        </div>
      )}
      {quantity > 0 && (<CBadge color="primary" className="mb-2 w-fit p-2 rounded-lg">ADDED</CBadge>)}
      <h5>{data.name}</h5>
      {data?.description && (
        <p className="mb-0 whitespace-pre-line">{data?.description}</p>
      )}
      <span className="mt-2 d-inline-flex">
        <h4>
          {formatCurrency(
            price.tax_behaviour === TAX_BEHAVIOUR.inclusive ? price.taxed_amount : price.amount,
            price.currency,
          )}
        </h4>
        <h6 className="mt-auto ml-1 h-fit">
          /
          {t('labels.month')}
        </h6>
      </span>
      <PricingTiers data={data.price.tiers} />
      <p className="mt-2">{renderTax()}</p>
      {isPrimary && !isAdded && (
        <Button
          type="primary"
          size="large"
          className="tw-mt-small"
          onClick={onAdd}
        >
          {t('buttons.select')}
        </Button>
      )}
      {(!isPrimary || isAdded) && (
        <div className="tw-mt-small border rounded-lg py-1 px-3 w-fit d-flex justify-content-between items-center">
          <CIcon
            name="cil-minus"
            size="lg"
            className="my-auto cursor-pointer"
            onClick={onRemove}
          />
          <h5 className="mx-4 my-auto user-select-none">{quantity}</h5>
          <CIcon
            name="cil-plus"
            size="lg"
            className="my-auto cursor-pointer"
            onClick={onAdd}
          />
        </div>
      )}
    </>
  );

  return (
    <Container
      className={classnames('tw-origin-center-right transform-style-3d', {
        shadow: isPrimary,
        'border justify-content-center': isSecondary,
        'border-primary': isSecondary && isAdded,
        '-tw-translate-x-full -tw-rotate-y-180': isEditing,
      })}
    >
      {renderEditContent()}
      {renderPreviewContent()}
    </Container>
  );
}

const Container = tw.div`lg:col-span-1 md:col-span-1 col-span-3 bg-white shadow rounded-lg p-4 flex flex-col relative duration-1000`;
