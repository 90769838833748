import { createActions } from 'redux-actions';

export const actionCreators = createActions({
  activeTab: {
    set: undefined,
  },
  currentCompany: {
    set: undefined,
    update: undefined,
    reset: undefined,
    setBusinessProfile: undefined,
    setFranchise: undefined,
    updateMerchant: undefined,
  },
  currentFranchise: {
    set: undefined,
    reset: undefined,
  },
  currentServiceProvider: {
    set: undefined,
    reset: undefined,
  },
  expandedProducts: {
    set: undefined,
    reset: undefined,
    toggle: undefined,
  },
  initialData: {
    set: undefined,
    resetProfile: undefined,
    updateCompanies: undefined,
    markNotificationsSeen: undefined,
    toggleLoading: undefined,
  },
  sideBar: {
    setBadge: undefined,
    decrementBadge: undefined,
  },
  storefront: {
    setStore: undefined,
  },
  appStatus: {
    update: undefined,
  },
});
