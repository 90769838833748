import ApiResourceManager from '../ApiResourceManager';

export default class GoogleBusinessManager extends ApiResourceManager {
  login = (data) => this.request({
    method: 'POST',
    url: '/google-business/login',
    data,
  });

  fetchGoogleBusiness = () => this.request({
    method: 'GET',
    url: '/google-business',
  });

  fetchGoogleBusinessSettings = () => this.request({
    method: 'GET',
    url: '/google-business/settings',
  });

  updateGoogleBusiness = (data) => this.request({
    method: 'PUT',
    url: '/google-business',
    data,
  });

  updateGoogleBusinessSettings = (data) => this.request({
    method: 'PUT',
    url: '/google-business/settings',
    data,
  });

  getReviews = (params) => this.request({
    method: 'GET',
    url: '/google-business/reviews',
    params,
  });

  respondToReview = (id, data) => this.request({
    method: 'POST',
    url: `/google-business/reviews/${id}/replies`,
    data,
  });

  generateAIReply = (id) => this.request({
    method: 'POST',
    url: `/google-business/reviews/${id}/ai-reply`,
  });

  updateLocationDetails = (data) => this.request({
    method: 'PUT',
    url: '/google-business/location',
    data,
  });

  syncFromGoogle = () => this.request({
    method: 'GET',
    url: '/google-business/sync',
  });

  publishToGoogle = () => this.request({
    method: 'POST',
    url: '/google-business/publish',
  });

  generateDescription = (data) => {
    const params = new URLSearchParams(data).toString();
    return this.request({
      method: 'GET',
      url: `/google-business/descriptions?${params}`,
    });
  };

  getCompanyStats = (params) => this.request({
    method: 'GET',
    url: '/google-business/company-stats',
    params,
  });
}
