import { SUBSCRIPTION_BILLING_MODES } from '../../../../../services/exports/Constants';

export default {
  titles: {
    draft: 'Your order (cost per month)',
    main: 'Your subscription',
    billing_settings: 'Billing settings',
  },
  form: {
    billing_mode: {
      label: 'Billing mode',
    },
    has_trial: {
      label: 'Add free trial days',
    },
    trial_days: {
      label: 'How many trial days should be there?',
      placeholder: '21',
      validation: {
        required: 'Trial days is required',
        min: '{{value}} is the minimal value for the trial days',
        max: '{{value}} is the maximal value for the trial days',
      },
    },
  },
  fields: {
    created_at: 'Subscribed at <strong>{{date}}</strong>',
    trial_ends_at: 'On trial until <strong>{{date}}</strong>',
    trial_days: '{{trialDays}} days trial starts at the launch date, you will be charged when trial is over',
    resumable_until: 'Resumable until <strong>{{date}}</strong>',
    ended_at: 'Ended at <strong>{{date}}</strong>',
    next_bill_at: 'Next bill at <strong>{{date}}</strong>',
    product: 'Product',
    quantity: 'Quantity',
    price: 'Net Price',
    tax: 'Tax',
    amount: 'Amount',
    net: 'Net',
    tax_percentage: 'Tax {{rate}}%',
    total: 'Total charge per month',
  },
  billing_modes: {
    [SUBSCRIPTION_BILLING_MODES.stripe]: 'Charge from payment method',
    [SUBSCRIPTION_BILLING_MODES.internal_invoicing]: 'Charge from sales',
  },
  tags: {
    active: 'Active',
    on_trial: 'On trial',
    launch_pending: 'On hold until launch date',
    on_grace_period: 'On grace period',
    past_due: 'Past due',
    cancelled: 'Cancelled',
  },
  labels: {
    included: 'included',
    sales_based_discount: 'Some of items have sales-based price, discount is auto-applied upon subscription creation/update',
  },
  buttons: {
    edit: 'Edit',
    cancel: 'Cancel',
  },
  toasts: {
    cancel_subscription: 'If you want to cancel your subscription, please reach out via call or email to your {{provider}} contact person. Your contact will cancel the subscription for you immediately.',
  },
};
