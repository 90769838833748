import ApiResourceManager from '../ApiResourceManager';

export default class GoogleAdsManager extends ApiResourceManager {
  connectCampaign = (data) => this.request({
    method: 'POST',
    url: '/google-ads/connect-campaign',
    data,
  });

  getCampaigns = () => this.request({
    method: 'GET',
    url: '/google-ads/campaigns',
  });

  getCompanyCampaigns = () => this.request({
    method: 'GET',
    url: '/google-ads/company-campaigns',
  });

  getCampaign = (id) => this.request({
    method: 'GET',
    url: `/google-ads/campaigns/${id}`,
  });

  getCompanyStats = (params) => this.request({
    method: 'GET',
    url: '/google-ads/company-stats',
    params,
  });
}
