import { PAYOUT_INTERVAL, PAYOUT_PROVIDERS, PAYOUT_STATUSES } from '../../../../../../services/exports/Constants';

export default {
  headers: {
    payout_info: 'Auszahlungsinfo',
    payouts: 'Auszahlungen',
  },
  form: {
    invoicing_emails: {
      label: 'Rechnungen senden an email',
      placeholder: 'E-Mail-Adresse eingeben',
    },
    payout_schedule: {
      label: 'Auszahlungsplan',
      options: {
        [PAYOUT_INTERVAL.MANUAL]: 'Angehaltene Auszahlungen',
        [PAYOUT_INTERVAL.DAILY]: 'Tägliche Auszahlungen',
        [PAYOUT_INTERVAL.WEEKLY]: 'Wöchentliche Auszahlungen',
        [PAYOUT_INTERVAL.MONTHLY]: 'Monatliche Auszahlungen',
      },
    },
  },
  fields: {
    id: 'ID',
    company: 'Restaurant',
    amount: 'Betrag',
    cash_amount: 'Bargeldbetrag',
    period: 'Zeitraum',
    paid_at: 'Bezahlt am',
    provider: 'Zahlungsanbieter',
    status: 'Status',
    actions: 'Aktionen',
  },
  buttons: {
    save: 'Speichern',
    actions: 'Aktionen',
    open_report: 'Bericht öffnen',
    open_tracking_url: 'Auszahlung verfolgen',
  },
  statuses: {
    [PAYOUT_STATUSES.created]: 'Ausstehend',
    [PAYOUT_STATUSES.processing]: 'Bearbeitung',
    [PAYOUT_STATUSES.paid]: 'Bezahlt',
    [PAYOUT_STATUSES.failed]: 'Fehlgeschlagen',
    undefined: 'Unbekannt',
    null: 'Unbekannt',
  },
  providers: {
    [PAYOUT_PROVIDERS.stripe]: 'Stripe',
    [PAYOUT_PROVIDERS.paypal]: 'PayPal',
    [PAYOUT_PROVIDERS.cash]: 'Bargeld',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
    successfully_updated: 'Erfolgreich aktualisiert',
  },
};
