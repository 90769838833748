import ApiResourceManager from '../ApiResourceManager';

export default class EmailMarketingManager extends ApiResourceManager {
  getMessages = (params) => this.request({
    method: 'GET',
    url: `/messages`,
    params,
  });

  getTemplates = () => this.request({
    method: 'GET',
    url: '/templates',
  });

  setTemplateActive = (id, isActive) => this.request({
    method: 'POST',
    url: `/templates/${id}/set-active`,
    data: { is_active: isActive },
  });

  getTemplate = (id) => this.request({
    method: 'GET',
    url: `/templates/${id}`,
  });

  getTemplatePreview = (id, templateData) => this.request({
    method: 'GET',
    url: `/templates/${id}/preview`,
    params: templateData,
  });

  getCompanyStats = (params) => this.request({
    method: 'GET',
    url: '/marketing/company-stats',
    params,
  });

  getCompanyOverview = () => this.request({
    method: 'GET',
    url: '/company-overview',
  });

  getServiceProviderOverview = (params) => this.request({
    method: 'GET',
    url: '/service-provider-overview',
    params,
  });
}
