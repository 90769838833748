import { DeliveryProvider } from '../../../../../services/exports/Constants';

export default {
  title: 'Check address serviceability',
  or: 'or',
  form: {
    company: {
      label: 'Select restaurant',
    },
    address: {
      label: 'Type in the address',
    },
  },
  result: {
    positive: '{{address}} is eligible for external delivery',
    negative: '{{address}} is not eligible for external delivery',
  },
  providers: {
    [DeliveryProvider.FirstDelivery]: 'Uber',
    [DeliveryProvider.UberDrive]: 'Uber',
    [DeliveryProvider.WoltDrive]: 'Wolt',
  },
  buttons: {
    check: 'Check',
  },
  toasts: {
    error: 'Failed to check address serviceability',
  },
};
