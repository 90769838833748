import ApiResourceManager from '../ApiResourceManager';
import ReduxHooks from '../../../store/ReduxHooks';
import { actionCreators } from '../../../store/actions';

export default class StorefrontManager extends ApiResourceManager {
  create = async (data) => {
    const response = await this.request({
      method: 'POST',
      url: '/storefront',
      data,
    });

    if (response.success) {
      ReduxHooks.dispatch(actionCreators.currentCompany.set(response.data));
    }

    return response;
  };

  get = async () => this.request({
    method: 'GET',
    url: '/storefront',
  });

  delete = async () => this.request({
    method: 'DELETE',
    url: '/storefront',
  });

  toggleReservations = async (data) => this.request({
    method: 'POST',
    url: '/storefront/toggle-reservations',
    data,
  });

  syncCompany = (id) => this.request({
    method: 'POST',
    url: `/storefront/companies/${id}/sync`,
  });

  getStats = (params) => this.request({
    method: 'GET',
    url: '/storefront/stats',
    params,
  });
}
