export default {
  title: 'Neue Artikel',
  form: {
    categories: {
      label: 'Kategorien',
    },
    printer: {
      label: 'Drucker',
    },
    name: {
      label: 'Name',
      placeholder: 'Cheeseburger',
    },
    description: {
      label: 'Beschreibung',
      placeholder:
        '180g beef Patty, Salat, Tomaten, Gurken, Jalapeños and Yuppi sauce',
    },
    price: {
      label: 'Basis Preis',
      placeholder: '0.00{{currency}}',
    },
    has_smart_pricing: {
      label: 'Spezielle Preise für Abholung und Lieferung',
    },
    pickup_price: {
      label: 'Abholpreis',
      placeholder: 'Nicht spezifiziert',
    },
    delivery_price: {
      label: 'Lieferpreis',
      placeholder: 'Nicht spezifiziert',
    },
    image: {
      label: 'Bild (optional)',
    },
    tax_percentage: {
      label: 'Mehrwertsteuer %',
    },
    has_combo_tax: {
      label: 'Hat Combo-Steuer',
    },
    is_available: {
      label: 'Auf Lager',
    },
    is_visible: {
      label: 'Sichtbar',
    },
    is_promotable: {
      label: 'Promotable',
    },
    free_eligible: {
      label: 'Gratis verfügbar',
    },
    modifiers: {
      label: 'Optionen',
    },
    identifiers: {
      label: 'Kennzeichnungen',
    },
  },
  labels: {
    smart_pricing_update_warning: 'Durch das Aktualisieren werden alle vorhandenen Smart Pricing-Regeln für dieses Produkt überschrieben.',
    smart_pricing_remove_warning: 'Nach dem Speichern werden alle vorhandenen Smart Pricing-Regeln für dieses Produkt entfernt.',
  },
  buttons: {
    save: 'Speichern',
    close: 'Schließen',
  },
  toasts: {
    changes_got_saved: 'Die Änderungen wurden erfolgreich gespeichert!',
  },
};
