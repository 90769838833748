export default {
  toasts: {
    failed_to_create_website: 'Erstellung der Website fehlgeschlagen',
  },
  steps: {
    information: 'Informationen',
    template: 'Vorlage',
  },
  buttons: {
    next: 'Weiter',
    previous: 'Zurück',
    create_website: 'Website erstellen',
    preview_about_us: 'Über uns (Vorschau)',
  },
  about_us_modal: {
    title: 'Über uns (Vorschau)',
  },
  error_messages: {
    missing_fields:
            'Einige erforderliche Felder fehlen. Bitte füllen Sie alle notwendigen Informationen aus, bevor Sie fortfahren.',
  },
};
