import qs from 'qs';
import ApiResourceManager from '../ApiResourceManager';

export default class ReservationsManager extends ApiResourceManager {
  ON_BEHALF_OF_COMPANY = 'reservation-on-behalf-of-company';

  getSettings = (id) => this.request({
    method: 'GET',
    url: `/merchant/companies/${id}/reservation-settings`,
  });

  postSettings = (id, data) => this.request({
    method: 'POST',
    url: `/merchant/companies/${id}/reservation-settings`,
    data,
  });

  getReservations = (params) => this.request({
    method: 'GET',
    url: '/merchant/reservations',
    params,
    paramsSerializer: qs.stringify,
  });

  getReservation = (id) => this.request({
    method: 'GET',
    url: `/merchant/reservations/${id}`,
  });

  postReservation = (companyId, data) => this.request({
    method: 'POST',
    url: `/merchant/companies/${companyId}/reservations`,
    data,
  });

  putReservation = (id, data) => this.request({
    method: 'PUT',
    url: `/merchant/reservations/${id}`,
    data,
  });

  putReservationStatus = (id, data) => this.request({
    method: 'PUT',
    url: `/merchant/reservation-status/${id}`,
    data,
  });

  getStats = (params) => this.request({
    method: 'GET',
    url: '/reservations/stats',
    params,
    paramsSerializer: qs.stringify,
  });

  getCompanyStats = (params) => this.request({
    method: 'GET',
    url: '/reservations/company-stats',
    params,
    paramsSerializer: qs.stringify,
  });

  getSchedule = (googlePlaceId, params) => this.request({
    method: 'GET',
    url: `/companies/${googlePlaceId}/schedules`,
    params,
    paramsSerializer: qs.stringify,
  });

  getFranchise = (id) => this.request({
    method: 'GET',
    url: `/merchant/franchises/${id}`,
  });
}
