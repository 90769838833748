export default {
  title:
    'Klicke auf die Schaltfläche unten, um deine Zahlungsinformationen zu überprüfen und zu bestätigen.',
  description:
    'Wir arbeiten mit Stripe zusammen, um Zahlungen abzuwickeln. Stripe ist die sicherste Zahlungsplattform, die von den größten Unternehmen weltweit genutzt wird.',
  form: {
    terms_acceptance: {
      label: 'Indem Sie fortfahren, erklären Sie sich mit unserer <FoodAmigosTerms>Dienstleistungsvereinbarung</FoodAmigosTerms> und der <StripeTerms>Stripe Connected Account-Vereinbarung</StripeTerms> einverstanden.',
    },
  },
  labels: {
    stripe_requirements: 'Stripe hat einige Kundeninformationen als fehlend oder ungültig markiert. Bitte beheben Sie folgende Fehler, indem Sie auf die Schaltfläche "Stripe-Profil öffnen" klicken:',
    errors: 'Fehler',
  },
  buttons: {
    proceed: 'Bestätige meine Zahlungsinformationen',
  },
  toasts: {
    something_went_wrong: 'Etwas ist schief gelaufen, bitte kontaktieren Sie den technischen Support',
  },
};
