import React, { useEffect, useState, useMemo } from 'react';
import { collect } from 'collect.js';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {
  Button, Col, Dropdown, Flex, Input, Row, Space, Statistic, Table, Tag,
} from 'antd';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  ExportOutlined,
  InfoCircleOutlined,
  MoreOutlined,
  ReloadOutlined,
  ShoppingCartOutlined,
  SyncOutlined,
  TabletOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { debounce } from 'lodash';
import {
  COUPON_TYPES,
  ORDER_PAYMENT_STATUSES,
  PAYMENT_METHODS,
  FULFILLMENT_STATUSES,
  ORDER_METHODS,
  ROOM_SERVICE_LOCATION_TYPES, DeliveryProvider,
} from '../../../services/exports/Constants';
import OrderDetailsModal from '../../../modals/order/OrderDetailsModal';
import useApiClient from '../../../hooks/api/useApiClient';
import useHelpers from '../../../hooks/context/useHelpers';
import useForm from '../../../hooks/utility/useForm';
import CompanySelect from '../../../components/form/CompanySelect';
import DateRangePicker, { filterFactory, filter_today, variant_dropdown } from '../../../components/filters/DateRangePicker';
import ClickToCopy from '../../../components/ClickToCopy';
import OrderDeliveryDetailsModal from '../../../modals/order/OrderDeliveryDetailsModal';

export const action_launch_orders_portal_app = 'launch_orders_portal_app';
export const action_create_dummy_order = 'create_dummy_order';

export default function OrdersView() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Sales:Orders' });

  const { OrdersManager, MagicLinksManager, StatsManager } = useApiClient();
  const { formatCurrency, formatDateTime } = useHelpers();

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);
  const { company } = useSelector((state) => state.currentCompany);

  const [data, setData] = useState([]);
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMetrics, setLoadingMetrics] = useState(false);
  const [actionPending, setActionPending] = useState(false);

  // Modals
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showOrderDetailsModal, setShowOrderDetailsModal] = useState(false);
  const [showDeliveryDetailsModal, setShowDeliveryDetailsModal] = useState(false);

  const { data: filters, update: updateFilter } = useForm({
    companies: company?.id ? [company] : [],
    status: [],
    customer_details: null,
    ...filterFactory(filter_today),
  });

  useEffect(() => {
    loadData(data?.current_page ?? 1);
  }, [
    serviceProvider?.id,
    filters.companies,
    filters.start_date,
    filters.end_date,
  ]);

  const debounced = debounce(
    async () => loadOrders(data?.current_page),
    500,
  );

  useEffect(() => {
    debounced();

    return () => debounced.cancel();
  }, [
    filters.customer_details,
  ]);

  async function loadData(page) {
    loadOrders(page);
    loadMetrics();
  }

  async function loadOrders(page, extraFilters = {}) {
    setLoading(true);
    const { data, success } = await OrdersManager.get({
      filter: {
        companies: collect(filters.companies).pluck('id').toArray(),
        created_between: filters.start_date && filters.end_date
          ? [filters.start_date, filters.end_date]
          : undefined,
        customer_details: filters.customer_details,
        ...extraFilters,
      },
      page,
    });
    setLoading(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_load_data'));
    }

    return setData(data);
  }

  async function loadMetrics() {
    setLoadingMetrics(true);
    const { success, data: statsData } = await StatsManager.getCompanyStats({
      companies: filters.companies?.length > 0
        ? collect(filters.companies).pluck('id').toArray()
        : ['*'],
      start_date: filters.start_date,
      end_date: filters.end_date,
      metrics: [
        'sales',
      ],
      with_interval: false,
    });
    setLoadingMetrics(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_load_data'));
    }

    setStats(statsData);
  }

  async function createDummyOrder() {
    setActionPending(true);
    const { success, data: responseData } = await OrdersManager.createDummyOrder();
    setActionPending(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_create_dummy_order'));
    }

    toast.success(t('toasts.dummy_order_created'));
    setData((current) => ({
      ...current,
      data: [
        responseData.data,
        ...current.data,
      ],
    }));
  }

  async function launchOrdersPortal() {
    setActionPending(true);
    const { success, data: responseData } = await MagicLinksManager.create({
      app: 'staff',
    });
    setActionPending(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_launch_orders_portal_app'));
    }

    return window.open(responseData.url);
  }

  const columns = [
    {
      key: 'actions',
      fixed: 'left',
      render: (text, item) => (
        <Dropdown
          menu={{
            items: [
              {
                key: 'show_order_details',
                label: t('buttons.show_order_details'),
                onClick: () => toggleOrderDetailsModal(item),
                icon: <InfoCircleOutlined />,
              },
              {
                ...item.delivery_provider === DeliveryProvider.UberDrive && !!item.delivery?.id && {
                  key: 'update_dropoff_time',
                  label: t('buttons.update_dropoff_time'),
                  onClick: () => toggleDeliveryDetailsModal(item),
                  icon: <InfoCircleOutlined />,
                },
              },
              {
                key: 'track_order',
                label: t('buttons.track_order'),
                onClick: () => window.open(`${company?.web_shop_url}/order-details/${item.uuid}`),
                icon: <ExportOutlined />,
              },
            ],
          }}
        >
          <MoreOutlined />
        </Dropdown>
      ),
    },
    {
      key: 'number',
      title: '#',
      dataIndex: ['fulfillment', 'number'],
      fixed: 'left',
    },
    {
      key: 'company',
      title: t('fields.company'),
      dataIndex: ['company', 'name'],
    },
    {
      key: 'placed_at',
      title: t('fields.order_placed'),
      render: (_, item) => formatDateTime(item.created_at),
    },
    {
      key: 'fulfillment_status',
      title: t('fields.fulfillment_status'),
      render: (_, item) => {
        const color = ({
          [FULFILLMENT_STATUSES.created]: 'warning',
          [FULFILLMENT_STATUSES.reviewed]: 'warning',
          [FULFILLMENT_STATUSES.accepted]: 'processing',
          [FULFILLMENT_STATUSES.preparing]: 'processing',
          [FULFILLMENT_STATUSES.on_delivery]: 'processing',
          [FULFILLMENT_STATUSES.ready]: 'processing',
          [FULFILLMENT_STATUSES.picked]: 'success',
          [FULFILLMENT_STATUSES.rejected]: 'error',
        })[item.fulfillment.status];

        const icon = ({
          [FULFILLMENT_STATUSES.created]: <ClockCircleOutlined />,
          [FULFILLMENT_STATUSES.reviewed]: <ClockCircleOutlined />,
          [FULFILLMENT_STATUSES.accepted]: <SyncOutlined spin />,
          [FULFILLMENT_STATUSES.preparing]: <SyncOutlined spin />,
          [FULFILLMENT_STATUSES.on_delivery]: <SyncOutlined spin />,
          [FULFILLMENT_STATUSES.ready]: <SyncOutlined spin />,
          [FULFILLMENT_STATUSES.picked]: <CheckCircleOutlined />,
          [FULFILLMENT_STATUSES.rejected]: <CloseCircleOutlined />,
        })[item.fulfillment.status];

        return (
          <Flex gap="4px 0">
            <Tag color={color} icon={icon}>
              {t(
                `fulfillment_statuses.${item.method}.${item.fulfillment.status}`,
                t(`fulfillment_statuses.default.${item.fulfillment.status}`),
              )}
            </Tag>
            {item.fulfillment.status === FULFILLMENT_STATUSES.rejected && item.rejection_reason === 'auto' && (
              <Tag color="warning" icon={<ExclamationCircleOutlined />}>{t('tags.rejected_automatically')}</Tag>
            )}
            {item.fulfillment.status === FULFILLMENT_STATUSES.rejected && item.rejection_reason !== 'auto' && (
              <Tag color="warning" icon={<ExclamationCircleOutlined />}>{t('tags.rejected_manually')}</Tag>
            )}
          </Flex>
        );
      },
    },
    {
      key: 'total',
      title: t('fields.total'),
      render: (_, item) => formatCurrency(item.total, item.currency),
    },
    {
      key: 'customer',
      title: t('fields.customer'),
      render: (_, item) => `${item.customer_first_name ?? ''} ${item.customer_last_name ?? ''}`.trim(),
    },
    {
      key: 'items',
      title: t('fields.items'),
      render: (_, item) => collect(item.line_items).sum('quantity'),
    },
    {
      key: 'method',
      title: t('fields.method'),
      render: (_, item) => t(
        item.method === ORDER_METHODS.room_service
          ? `order_methods.${item.method}.${item.room_service_location?.type}`
          : `order_methods.${item.method}`,
      ),
    },
    {
      key: 'tags',
      title: t('fields.tags'),
      render: (_, item) => (
        <Flex gap="4px 0">
          {item?.is_dummy && <Tag color="error" icon={<ExclamationCircleOutlined />}>{t('tags.dummy')}</Tag>}
          {item?.scheduled_for && <Tag color="processing" icon={<ClockCircleOutlined />}>{t('tags.scheduled')}</Tag>}
          {item?.coupon_type === COUPON_TYPES.REWARD && <Tag color="gold">{t('tags.reward')}</Tag>}
          {item?.payment?.payment_method_id === PAYMENT_METHODS.CASH && (
            <Tag color="default" icon={<ExclamationCircleOutlined />}>{t(item?.company?.overwrite_cash_payments_to_invoice ? 'tags.invoice' : 'tags.cash')}</Tag>
          )}
          {item?.method === ORDER_METHODS.pickup && <Tag color="green">{t('tags.pickup')}</Tag>}
          {item?.method === ORDER_METHODS.delivery && item?.delivery_provider === DeliveryProvider.Standalone && <Tag color="orange">{t('tags.internal_delivery')}</Tag>}
          {item?.method === ORDER_METHODS.delivery && item?.delivery_provider !== DeliveryProvider.Standalone && <Tag color="lime">{t('tags.external_delivery')}</Tag>}
          {item?.method === ORDER_METHODS.room_service && item.room_service_location?.type === ROOM_SERVICE_LOCATION_TYPES.room_service && (
            <Tag color="volcano">{t('tags.room_service')}</Tag>
          )}
          {item?.method === ORDER_METHODS.room_service && item.room_service_location?.type === ROOM_SERVICE_LOCATION_TYPES.table_service && (
            <Tag color="cyan">{t('tags.table_service')}</Tag>
          )}
          {item.tags.map((item) => <Tag color="geekblue">{t(`tags.${item}`, { defaultValue: item })}</Tag>)}
        </Flex>
      ),
    },
    {
      key: 'payment_status',
      title: t('fields.payment_status'),
      render: (_, item) => {
        const getPaymentStatus = () => {
          if (item.payment_status === ORDER_PAYMENT_STATUSES.paid) {
            return ORDER_PAYMENT_STATUSES.paid;
          }

          if (item.payment_status === ORDER_PAYMENT_STATUSES.refunded) {
            return ORDER_PAYMENT_STATUSES.refunded;
          }

          return ORDER_PAYMENT_STATUSES.partially_refunded;
        };

        const color = ({
          [ORDER_PAYMENT_STATUSES.paid]: 'success',
          [ORDER_PAYMENT_STATUSES.partially_refunded]: 'warning',
          [ORDER_PAYMENT_STATUSES.refunded]: 'error',
        })[getPaymentStatus()];

        const icon = ({
          [ORDER_PAYMENT_STATUSES.paid]: <CheckCircleOutlined />,
          [ORDER_PAYMENT_STATUSES.partially_refunded]: <WarningOutlined />,
          [ORDER_PAYMENT_STATUSES.refunded]: <CloseCircleOutlined />,
        })[getPaymentStatus()];

        return (
          <Flex gap="4px 0">
            <Tag color={color} icon={icon}>{t(`payment_statuses.${getPaymentStatus()}`)}</Tag>
          </Flex>
        );
      },
    },
    {
      key: 'id',
      title: t('fields.id'),
      render: (_, item) => <ClickToCopy>{item.id}</ClickToCopy>,
    },
  ];

  function toggleOrderDetailsModal(order) {
    setSelectedOrder(order);
    setShowOrderDetailsModal(!showOrderDetailsModal);
  }

  const toggleDeliveryDetailsModal = (order) => {
    setSelectedOrder(order);
    setShowDeliveryDetailsModal(!showDeliveryDetailsModal);
  };

  const renderOrderDetailsModal = useMemo(() => showOrderDetailsModal && (
    <OrderDetailsModal
      show={showOrderDetailsModal}
      toggleModal={toggleOrderDetailsModal}
      data={selectedOrder}
    />
  ), [selectedOrder, showOrderDetailsModal]);

  const dropdownItems = [
    {
      key: action_launch_orders_portal_app,
      label: t(`buttons.${action_launch_orders_portal_app}`),
      icon: <TabletOutlined />,
    },
    { type: 'divider' },
    {
      key: action_create_dummy_order,
      label: t(`buttons.${action_create_dummy_order}`),
      icon: <ShoppingCartOutlined />,
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <h2>{t('title')}</h2>
        {company?.id && (
          <Dropdown
            menu={{
              items: dropdownItems,
              onClick: (e) => ({
                [action_launch_orders_portal_app]: launchOrdersPortal,
                [action_create_dummy_order]: createDummyOrder,
              })[e.key](),
            }}
            className="tw-justify-end"
          >
            <Button loading={actionPending}>
              <Space>
                {t('buttons.actions')}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        )}
      </div>
      <Row gutter={12}>
        <Col span={12} lg={6}>
          <Statistic
            title={t('metrics.total_orders')}
            value={data?.meta?.total}
            loading={loadingMetrics}
          />
        </Col>
        <Col span={12} lg={6}>
          <Statistic
            title={t('metrics.total_sales')}
            value={stats?.sales?.value?.formatted}
            loading={loadingMetrics}
          />
        </Col>
      </Row>
      <div className="mt-3 tw-bg-background-inkWhite-white_0 tw-shadow tw-rounded-lg">
        <div
          className="tw-flex tw-justify-between tw-items-center tw-gap-4 tw-p-small border-b tw-border-brand-inkGrey-grey_2"
        >
          <div className="lg:tw-w-2/5">
            <DateRangePicker
              defaultFilter={filter_today}
              variant={variant_dropdown}
              onChange={(value) => {
                updateFilter('start_date', value.start_date);
                updateFilter('end_date', value.end_date);
              }}
            />
            <CompanySelect
              id="companies"
              value={filters.companies}
              onChange={(value) => updateFilter('companies', value)}
              className="tw-mt-small"
              isMulti
            />
            <Input.Search
              value={filters.customer_details}
              onChange={(e) => updateFilter('customer_details', e.target.value)}
              className="tw-mt-small"
              placeholder={t('form.customer_details.placeholder')}
            />
          </div>
          <div className="tw-flex tw-items-center tw-justify-end">
            <Button size="large" icon={<ReloadOutlined />} onClick={() => loadData(data.current_page)} />
          </div>
        </div>
        <Table
          dataSource={data.data}
          columns={columns}
          loading={loading}
          className="rounded-none table-responsive position-initial"
          rowKey={(item) => item.id}
          pagination={{
            current: data?.meta?.current_page,
            total: data?.meta?.total,
            pageSize: data?.meta?.per_page,
            onChange: (page) => loadData(page),
            rootClassName: 'tw-overflow-hidden',
          }}
          scroll={{ x: 'max-content' }}
        />
      </div>
      {renderOrderDetailsModal}
      {showDeliveryDetailsModal
          && <OrderDeliveryDetailsModal data={selectedOrder} show toggleModal={toggleDeliveryDetailsModal} />}
    </>
  );
}
