export default {
  title: 'Deliverect',
  form: {
    channel_link_id: {
      label: 'Channel Link ID: ',
    },
    store_id: {
      label: 'EXTERNAL LOCATION ID: ',
    },
  },
  buttons: {
    save: 'Save',
  },
  toasts: {
    error: 'Failed to save changes',
    success: 'Changes got saved',
  },
  instructions: {
    title: 'Setup instructions:',
  },
};
