import {
  CompanyCreationModes,
  CompanyCreationSteps,
  BusinessProfileOption,
  FranchiseOption,
} from '../../../../../views/companies';
import { BusinessType } from '../../../../../services/exports/Constants';

export default {
  title: 'New restaurant',
  sections: {
    new_company: {
      titles: {
        [CompanyCreationModes.demo]: 'New demo',
        [CompanyCreationModes.full]: 'New restaurant',
      },
      steps: {
        [CompanyCreationSteps.CompanyInfo]: 'Restaurant info',
        [CompanyCreationSteps.BusinessInfo]: 'Business info',
        [CompanyCreationSteps.AdditionalLocations]: 'Additional Restaurants',
      },
    },
    recently_created: 'Recently created',
  },
  form: {
    franchise: {
      label: 'Create Restaurant',
      tooltip: 'Please select whether you are creating a completely new restaurant/franchise or adding this restaurant to an existing franchise in our system.',
      options: {
        [FranchiseOption.CreateFranchise]: {
          title: 'Create a new restaurant/franchise',
          tooltip: 'We’re starting from scratch — no existing franchise or restaurant',
        },
        [FranchiseOption.UseExistingFranchise]: {
          title: 'Add this restaurant to an existing franchise',
          tooltip: 'It belongs to a franchise already in the system',
        },
      },
    },
    restaurants: {
      label: 'Associated Restaurant(s)',
    },

    business_profile: {
      label: 'Who is the owner?',
      tooltip: 'Does this restaurant belong to an owner/company that already exists in our system (e.g., “Luís Pizza GmbH”)? <br/> If yes, select that existing owner/company profile. Otherwise, create a new owner/company profile.',
      options: {
        [BusinessProfileOption.CreateBusinessProfile]: 'Create new owner/ company business profile',
        [BusinessProfileOption.UseExistingBusinessProfile]: 'Use existing owner/ company business profile',
      },
    },
    business_type: {
      label: 'Business type',
      placeholder: 'Select business type',
      options: {
        [BusinessType.Company]: 'Company',
        [BusinessType.Individual]: 'Sole proprietor',
      },
    },
    display_name: {
      label: 'Restaurant name',
      placeholder: 'Friendly name',
    },
    legal_name: {
      label: 'Legal name',
      placeholder: 'Legal name',
    },
    owner_phone_number: {
      label: 'Phone number of contact person',
      placeholder: '1785080120',
    },
    owner_email: {
      label: 'Email of contact person',
      placeholder: 'Enter Email',
    },
    password: {
      label: 'Password',
      placeholder: 'Enter Password',
    },

    additional_companies: {
      label: 'Additional restaurants under this brand',
      tooltip: 'Are there any additional restaurants under the same brand as {{company}}? <br/> If yes, click “Add Restaurant” to include more locations under this brand. If it’s the same owner but a different brand, do not add here. Instead, create it separately.',
    },
  },
  labels: {
    create_demo: 'Create demo',
    create_company: 'Create restaurant',
    business_type: 'Business type',
    display_name: 'Friendly name',
    legal_name: 'Legal name',
    owner_name: 'Owner name',
    address: 'Address',
  },
  fields: {
    name: 'Name',
    created_at: 'Created',
    actions: 'Actions',
  },

  buttons: {
    add_restaurant: '+ Add restaurant',
    continue: 'Continue',
    back: 'Back',
    create: 'Create restaurant',
    delete: 'Delete',
  },
  popups: {
    delete_demo: {
      title: 'Sure to delete?',
    },
  },
  toasts: {
    company_created: 'Restaurant created successfully',
    failed_to_create_company: 'Failed to create restaurant',
    failed_to_delete_demo: 'Failed to delete demo',
    demo_got_deleted: 'Demo deleted successfully',
    failed_to_load_recently_created_companies: 'Failed to load recently created restaurants',
  },
};
