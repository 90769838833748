import { ORDER_METHODS, PAYMENT_METHODS } from '../../../../../../services/exports/Constants';

export default {
  title: 'Payment methods',
  fields: {
    hidden_payment_methods: {
      label: 'Hidden payment methods',
    },
    has_online_payments: {
      label: 'Has online payments',
    },
    has_paypal_upcharge: {
      label: 'Upcharge 3.5% extra for paypal transactions',
    },
    has_cash_payments: {
      label: 'Has cash payments',
    },
    has_in_store_card_payments: {
      label: 'Has in-store card payments',
    },
    overwrite_cash_payments_to_invoice: {
      label: 'Label cash payments as "Invoice"',
    },
  },
  payment_methods: {
    [PAYMENT_METHODS.CARD]: 'Card',
    [PAYMENT_METHODS.PAYPAL]: 'PayPal',
    [PAYMENT_METHODS.APPLE_PAY]: 'Apple pay',
    [PAYMENT_METHODS.GOOGLE_PAY]: 'GPay',
    [PAYMENT_METHODS.SOFORT]: 'Sofort',
    [PAYMENT_METHODS.GIROPAY]: 'Giropay',
    [PAYMENT_METHODS.SEPA_DEBIT]: 'Sepa debit',
    [PAYMENT_METHODS.CASH]: 'Cash',
    [PAYMENT_METHODS.BACS_DEBIT]: 'Bacs debit',
  },
  order_methods: {
    [ORDER_METHODS.pickup]: 'Pickup',
    [ORDER_METHODS.delivery]: 'Delivery',
    [ORDER_METHODS.room_service]: 'Room/Table service',
  },
  modals: {
    confirmation: {
      title: 'Are you sure?',
      description: 'Once confirmed, online payments will be <strong>{{onlineState}}</strong>, cash payments will be <strong>{{cashState}}</strong>',
    },
  },
  labels: {
    off: 'off',
    on: 'on',
  },
  buttons: {
    save: 'Save',
  },
  toasts: {
    failed_to_save_changes: 'Failed to save changes',
    changes_got_saved: 'Changes got saved',
  },
};
