export default {
  title: 'Tracking-Links',
  utm_sources: {
    social_media: 'Social Media',
    flyer: 'Flyer',
    email: 'Email',
    google_business_profile: 'Google Business-Profil',
  },
  fields: {
    utm_source: {
      label: 'Quelle',
      placeholder: 'Quelle eingeben',
    },
    utm_medium: {
      label: 'Medium',
      placeholder: 'Medium eingeben',
    },
    utm_campaign: {
      label: 'Kampagne',
      placeholder: 'Kampagne eingeben',
    },
  },
  buttons: {
    link: 'Link',
    download: 'Download',
  },
  feedback: {
    copied: 'Link in die Zwischenablage kopiert',
  },
};
