import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { CFormGroup, CLabel, CSwitch } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { collect } from 'collect.js';
import DragNDropHelpers from '../../services/helpers/DragNDropHelpers';
import { DELIVERY_BRAND_BY_PROVIDER } from '../../services/exports/Constants';

function DeliveryProvidersList(props) {
  const { data, regionalDeliveryProviders, onChange } = props;
  const deliveryProviders = collect([...data])
    .push(...regionalDeliveryProviders)
    .unique()
    .toArray();
  const toggleDeliveryProviderAvailable = (deliveryProvider) => onChange(
    data.includes(deliveryProvider)
      ? data.filter((dp) => dp !== deliveryProvider)
      : [...data, deliveryProvider],
  );

  const onDragEnd = (result) => {
    DragNDropHelpers.onDragEnd(
      deliveryProviders,
      result,
      (res) => {
        const available = res.filter((provider) => data.includes(provider));

        onChange(available);
      },
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {deliveryProviders.map((item, index) => (
              <Draggable
                key={item}
                draggableId={item}
                index={index}
                isDragDisabled={data.length === 1 || !data.includes(item)}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    <CFormGroup
                      className="d-flex align-items-center"
                    >
                      <span className="h-100 d-flex" {...provided.dragHandleProps}>
                        <CIcon name="cil-resize-height" className="mr-3 text-black" />
                      </span>
                      <CSwitch
                        id={`delivery-provider-${item}`}
                        shape="pill"
                        color="primary"
                        size="lg"
                        variant="3d"
                        checked={data.includes(item)}
                        value={item}
                        onChange={(e) => toggleDeliveryProviderAvailable(e.target.value)}
                      />
                      <CLabel
                        htmlFor={`delivery-provider-${item}`}
                        className="my-auto ml-3 cursor-pointer"
                      >
                        {DELIVERY_BRAND_BY_PROVIDER[item]}
                      </CLabel>
                    </CFormGroup>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>

  );
}

export default DeliveryProvidersList;
