import { PAYOUT_INTERVAL, PAYOUT_PROVIDERS, PAYOUT_STATUSES } from '../../../../../../services/exports/Constants';

export default {
  headers: {
    payout_info: 'Payout info',
    payouts: 'Payouts',
  },
  form: {
    invoicing_emails: {
      label: 'Send invoices to',
      placeholder: 'Enter your email',
    },
    payout_schedule: {
      label: 'Payout schedule',
      options: {
        [PAYOUT_INTERVAL.MANUAL]: 'Paused payouts',
        [PAYOUT_INTERVAL.DAILY]: 'Daily payouts',
        [PAYOUT_INTERVAL.WEEKLY]: 'Weekly payouts',
        [PAYOUT_INTERVAL.MONTHLY]: 'Monthly payouts',
      },
    },
  },
  fields: {
    id: 'ID',
    company: 'Restaurant',
    amount: 'Amount',
    cash_amount: 'Cash amount',
    period: 'Period',
    paid_at: 'Paid at',
    provider: 'Provider',
    status: 'Status',
    actions: 'Actions',
  },
  buttons: {
    save: 'Save',
    actions: 'Actions',
    open_report: 'Open report',
    open_tracking_url: 'Track payout',
  },
  statuses: {
    [PAYOUT_STATUSES.created]: 'Pending',
    [PAYOUT_STATUSES.processing]: 'Processing',
    [PAYOUT_STATUSES.paid]: 'Paid',
    [PAYOUT_STATUSES.failed]: 'Failed',
    undefined: 'Unknown',
    null: 'Unknown',
  },
  providers: {
    [PAYOUT_PROVIDERS.stripe]: 'Stripe',
    [PAYOUT_PROVIDERS.paypal]: 'PayPal',
    [PAYOUT_PROVIDERS.cash]: 'Cash',
  },
  toasts: {
    failed_to_load_data: 'Failed to fetch data',
    successfully_updated: 'Successfully updated',
  },
};
