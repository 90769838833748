export default {
  fields: {
    demo: {
      label: 'Demo erstellen',
    },
    ai: {
      label: 'Ai optimiert',
    },
    restaurant_type: {
      label: 'Restauranttyp auswählen',
      types: {
        Pizza: 'Pizza 🍕',
        PizzaDelivery: 'Pizza-Lieferung 🍕🚗',
        PizzaNeapolitan: 'Neapolitanische Pizza 🍕',
        Italian: 'Italienisch 🇮🇹',
        Burger: 'Burger 🍔',
        Turkish: 'Türkisch 🇹🇷',
        Mexican: 'Mexikanisch 🌮',
        Sushi: 'Sushi 🍱',
        Japanese: 'Japanisch 🇯🇵',
        Vietnamese: 'Vietnamesisch 🇻🇳',
        Thai: 'Thailändisch 🇹🇭',
        Asian: 'Asiatisch 🍜',
        Indian: 'Indisch 🥘',
        Bowls: 'Bowls 🥣',
        Vegan: 'Vegan 🥑',
        Greek: 'Griechisch 🏛️',
        Cafe: 'Café ☕️',
        Brunch: 'Brunch 🍵',
        Oriental: 'Orientalisch / Naher Osten 🧆',
        Spanish: 'Spanisch / Tapas 🇪🇸',
        Persian: 'Persisch 🫖',
      },
    },
    plugins: {
      label: 'Plugins',
      reservations: 'Reservierungen',
      catering: 'Catering',
      events: 'Veranstaltungen',
    },
    quarter: {
      label: 'Bezirk',
      address_placeholder: 'Adresse eingeben',
      quarter_placeholder: 'Bezirk eingeben',
    },
    delivery_areas: {
      label: 'Welche Bereiche bedienen Sie in der Stadt?',
      city_placeholder: 'Stadt eingeben',
      district_placeholder: 'Bezirk 1, Bezirk 2, Bezirk 3',
    },
    best_dishes: {
      label: 'Was sind Ihre Top 3 Gerichte?',
      placeholder: 'Gerichte eingeben (Chicken Burger, Cheeseburger, etc.)',
    },
    dietary_attributes: {
      label: 'Haben Sie Optionen für Personen, die folgendes essen möchten:',
      placeholder: 'Bitte ernährungsbezogene Attribute auswählen',
      types: {
        vegetarian: 'Vegetarisch',
        vegan: 'Vegan',
        halal: 'Halal',
        glutenFree: 'Glutenfrei',
        dairyFree: 'Laktosefrei',
        spicy: 'Scharf',
      },
    },
    family_owned: {
      label: 'Familienbetrieb?',
      yes: 'Ja',
      no: 'Nein',
    },
    business_founding_year: {
      label: 'Wann wurde das Restaurant gegründet (in welchem Jahr)?',
    },
    business_owners: {
      label: 'Geschäftsinhaber',
      placeholder: 'Geschäftsinhaber eingeben',
    },
    notes: {
      label: 'Zusätzliche Notizen hinzufügen (optional)',
      placeholder: 'Beispiel: gemeinschaftsorientiertes Restaurant, Initiativen, von einem Paar geführt...',
    },
    about_us: {
      title: 'Über Uns (Vorschau)',
    },
  },
  sections: {
    plugins: 'Plugins',
    company_info: 'Firmeninformationen generieren',
  },
  buttons: {
    find_district: 'Bezirk finden',
    generate: 'Generieren',
    products: 'Produkte',
    add: 'Hinzufügen',
  },
};
