import React, { useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useApiClient from '../../hooks/api/useApiClient';
import usePermissions from '../../hooks/auth/usePermissions';
import useTheme from '../../hooks/context/useTheme';

export default function CompanySelect({
  value,
  except = [],
  onChange,
  id = undefined,
  isMulti = false,
  showAddress = false,
  className,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Form:CompanySelect' });

  const { franchise } = useSelector((state) => state.currentFranchise);

  const { CompaniesManager } = useApiClient();
  const { isMerchant } = usePermissions();
  const theme = useTheme();

  const [search, setSearch] = useState('');

  const loadData = async (
    search,
    loadedOptions,
    { page },
  ) => {
    let hasMore = false;

    const options = await CompaniesManager.get({
      name: search,
      franchise_id: isMerchant ? franchise?.id : null,
      except,
      page,
    }).then((response) => {
      const { data } = response;

      hasMore = data.current_page < data.last_page;

      return data.data;
    });

    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const getOptionLabel = (option) => {
    if (!showAddress || !option.address) {
      return option.name;
    }

    return (
      <div>
        <strong>{option.name}</strong>
        <p className="tiny tw-text-gray">
          {option.address}
          {', '}
          {option.city}
          {' '}
          {option.zip_code}
        </p>
      </div>
    );
  };

  return (
    <AsyncPaginate
      id={id}
      className={classnames('cursor-pointer border-none', className)}
      loadOptions={loadData}
      onChange={(item) => onChange(item)}
      onInputChange={setSearch}
      debounceTimeout={500}
      isClearable
      value={value}
      getOptionValue={(option) => option}
      getOptionLabel={getOptionLabel}
      isMulti={isMulti}
      placeholder={t('placeholder')}
      styles={{
        option: (styles, {
          isDisabled, isFocused, isSelected,
        }) => ({
          ...styles,
          // eslint-disable-next-line no-nested-ternary
          backgroundColor: isSelected ? '#f0fff3' : isFocused ? theme.color.brand.inkGrey.grey_6 : undefined,
          color: isSelected ? theme.color.brand.primary : theme.color.text.default,
          cursor: isDisabled ? 'not-allowed' : 'pointer',

          ':active': isSelected || isFocused ? theme.color.brand.primary : undefined,
        }),
        control: (styles) => ({
          ...styles,
          borderRadius: theme.dimension.borderRadius.mini,
        }),
        menu: (styles) => ({
          ...styles,
          borderRadius: theme.dimension.borderRadius.mini,
          zIndex: 1050,
        }),
      }}
      additional={{
        page: 1,
      }}
      defaultAdditional={{
        page: 2,
      }}
    />
  );
}
