import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../base-query';
import { serializeQueryArgs } from '../serialize-query-args';

export const menusApiSlice = createApi({
  reducerPath: 'menusApiSlice',
  baseQuery,
  endpoints: (builder) => ({
    getMenu: builder.query({
      query: ({ menuId, apiManager }) => apiManager.show(menuId),
    }),
  }),
  serializeQueryArgs,
});

export const { useGetMenuQuery, useLazyGetMenuQuery } = menusApiSlice;
