import { GOOGLE_ADS_CAMPAIGN_STATUS } from '../../../../../../../services/exports/Constants';

export default {
  buttons: {
    back: 'Zurück',
  },
  metrics: {
    impressions: {
      label: 'Impressionen',
      description: 'Die Anzahl der Einblendungen Ihrer Anzeige in den Google-Suchergebnissen oder auf Partner-Websites.',
    },
    clicks: {
      label: 'Klicks',
      description: 'Die Anzahl der Klicks auf Ihre Anzeige.',
    },
    budget_spent: {
      label: 'Ausgegebenes Budget',
      description: 'Der Gesamtbetrag, der für diese Kampagne ausgegeben wurde.',
    },
    absolute_top_impression_percentage: {
      label: 'Absolute Top-Impressionen %',
      description: 'Der Prozentsatz der Einblendungen Ihrer Anzeige an oberster Position in den Google-Suchergebnissen.',
    },
    calls: {
      label: 'Anrufe',
      description: 'Die Gesamtzahl der Anrufe, die über Ihre Anzeige eingegangen sind.',
    },
    phone_impressions: {
      label: 'Telefonnummer-Einblendungen',
      description: 'Die Anzahl der Einblendungen Ihrer Telefonnummer in der Anzeige.',
    },
    phone_through_rate: {
      label: 'Anrufdurchgangsrate',
      description: 'Der Prozentsatz der Telefonnummer-Einblendungen, die zu tatsächlichen Anrufen führten (Anrufe ÷ Telefonnummer-Einblendungen).',
    },
    directions: {
      label: 'Routenplanungen',
      description: 'Die Anzahl der Klicks auf die Wegbeschreibung zu Ihrem Geschäftsstandort.',
    },
    daily_budget: {
      label: 'Tagesbudget',
      description: 'Der maximale Betrag, der pro Tag für diese Kampagne festgelegt wurde.',
    },
    status: {
      label: 'Status',
      description: 'Der aktuelle Ausführungsstatus Ihrer Kampagne.',
    },
    ctr: {
      label: 'Klickrate',
      description: 'Der Prozentsatz der Anzeigeneinblendungen, die zu Klicks führten (Klicks ÷ Impressionen).',
    },
    average_cpc: {
      label: 'Durchschnittliche Kosten pro Klick',
      description: 'Der durchschnittliche Betrag, den Sie für jeden Klick auf Ihre Anzeige zahlen (Gesamtkosten ÷ Gesamtklicks).',
    },
    conversions_from_interactions_rate: {
      label: 'Konversionsrate',
      description: 'Der Prozentsatz der Anzeigeninteraktionen, die zu einer Konversion führten.',
    },
    start_date: {
      label: 'Startdatum',
      description: 'Das Datum, an dem die Kampagne gestartet wurde.',
    },
  },
  status: {
    [GOOGLE_ADS_CAMPAIGN_STATUS.UNSPECIFIED]: 'Nicht angegeben',
    [GOOGLE_ADS_CAMPAIGN_STATUS.ENABLED]: 'Aktiv',
    [GOOGLE_ADS_CAMPAIGN_STATUS.PAUSED]: 'Pausiert',
    [GOOGLE_ADS_CAMPAIGN_STATUS.UNKNOWN]: 'Unbekannt',
    [GOOGLE_ADS_CAMPAIGN_STATUS.REMOVED]: 'Entfernt',
  },
  error_messages: {
    loading_failed: 'Laden der Kampagnendetails fehlgeschlagen. Bitte versuche es erneut.',
  },
};
