import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, TimePicker } from 'antd';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import useScreenType from '../../hooks/context/useScreenType';
import useForm from '../../hooks/utility/useForm';
import useApiClient from '../../hooks/api/useApiClient';
import { useUpdateDeliveryMutation } from '../../store/api-slices';

const format = 'HH:mm';

export default function OrderDeliveryDetailsModal({
  show = false,
  toggleModal,
  data,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Modals:Order:OrderDeliveryDetailsModal' });
  const { isDesktop } = useScreenType();
  const dropoffEta = dayjs(data?.delivery.dropoff_estimate);
  const { DeliveriesManager } = useApiClient();
  const { data: formData, update } = useForm({
    dropoff_ready: '',
  });
  const [updateDelivery, { isLoading }] = useUpdateDeliveryMutation();
  const save = () => updateDelivery({
    apiManager: DeliveriesManager,
    id: data.delivery.id,
    data: formData,
  }).then((res) => {
    if (res.error) {
      toast.error(t('toasts.failed_to_save'));
    }
  });

  return (
    <Drawer
      id="order-delivery-details-modal"
      open={show}
      alignment="center"
      scrollable
      size="xl"
      width={window.innerWidth * (isDesktop ? 0.5 : 1)}
      onClose={toggleModal}
      title={t('title')}
      classNames={{
        body: '!tw-overflow-x-hidden',
      }}
    >
      <section className="tw-flex tw-flex-col h-full tw-gap-6">
        <fieldset className="tw-flex tw-flex-col tw-gap-3">
          <legend className="tw-text-base tw-font-medium">{t('labels.dropoff_time')}</legend>
          <p>
            {t('labels.confirmed')}
            <span className="ml-2">{dropoffEta.format(format)}</span>
          </p>
          <p>{t('labels.new_time')}</p>
          <TimePicker
            format={format}
            defaultValue={dropoffEta}
            onChange={(date) => update({ dropoff_ready: date.toISOString() })}
          />
        </fieldset>
        <Button
          className="mt-auto"
          type="primary"
          onClick={save}
          loading={isLoading}
        >
          {t('buttons.save')}
        </Button>
      </section>
    </Drawer>
  );
}
