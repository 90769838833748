import { GOOGLE_ADS_CAMPAIGN_STATUS } from '../../../../../../services/exports/Constants';

export default {
  title: 'Google Ads',
  search: {
    placeholder: 'Suche nach ID, Name oder Unternehmen',
  },
  columns: {
    name: 'Name',
    company: 'Unternehmen',
    connected: 'Verbunden',
    action: 'Aktion',
    details: 'Details',
    address: 'Adresse',
  },
  status: {
    [GOOGLE_ADS_CAMPAIGN_STATUS.UNSPECIFIED]: 'Nicht angegeben',
    [GOOGLE_ADS_CAMPAIGN_STATUS.ENABLED]: 'Aktiv',
    [GOOGLE_ADS_CAMPAIGN_STATUS.PAUSED]: 'Pausiert',
    [GOOGLE_ADS_CAMPAIGN_STATUS.UNKNOWN]: 'Unbekannt',
    [GOOGLE_ADS_CAMPAIGN_STATUS.REMOVED]: 'Entfernt',
  },
};
