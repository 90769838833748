export default {
  title: 'Tracking Links',
  utm_sources: {
    social_media: 'Social Media',
    flyer: 'Flyer',
    email: 'Email',
    google_business_profile: 'Google Business Profile',
  },
  fields: {
    utm_source: {
      label: 'Source',
      placeholder: 'Enter source',
    },
    utm_medium: {
      label: 'Medium',
      placeholder: 'Enter medium',
    },
    utm_campaign: {
      label: 'Campaign',
      placeholder: 'Enter campaign',
    },

  },
  buttons: {
    link: 'Link',
    download: 'Download',
  },
  feedback: {
    copied: 'Copied link to clipboard',
  },
};
