import collect from 'collect.js';
import qs from 'qs';
import ApiResourceManager from '../ApiResourceManager';

export default class ModifiersManager extends ApiResourceManager {
  create = (data) => this.request({
    method: 'POST',
    url: '/modifiers',
    data,
  });

  update = (id, data) => this.request({
    method: 'PUT',
    url: `/modifiers/${id}`,
    data,
  });

  delete = (ids) => this.request({
    method: 'DELETE',
    url: '/modifiers',
    params: {
      ids,
    },
  });

  reorder = (modifierGroupId, ids, type) => this.request({
    method: 'PUT',
    url: `/modifier-groups/${modifierGroupId}/modifiers-order`,
    data: {
      ids,
      type,
    },
  });

  toggleAvailable = (item) => this.update(item.id, {
    ...item,
    is_available: !item.is_available,
    modifier_groups: collect(item.modifier_groups).pluck('id').toArray(),
    _method: 'PUT',
  });

  toggleVisible = (item) => this.update(item.id, {
    ...item,
    is_visible: !item.is_visible,
    modifier_groups: collect(item.modifier_groups).pluck('id').toArray(),
    _method: 'PUT',
  });

  promote = (ids) => this.request({
    method: 'POST',
    url: '/promotable-modifiers',
    data: {
      ids,
    },
  });

  unpromote = (ids) => this.request({
    method: 'POST',
    url: '/non-promotable-modifiers',
    data: {
      ids,
    },
  });

  get = (params) => this.request({
    method: 'GET',
    url: '/modifiers',
    params,
    paramsSerializer: qs.stringify,
  });
}
