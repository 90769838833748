export default {
  tabs: {
    overview: 'Übersicht',
    orders: 'Bestellungen nach Quelle',
    google: 'Google',
    customers: 'Kunden',
    general: 'Allgemein',
  },
  date_range: {
    this_month: 'Diesen Monat',
    last_month: 'Letzten Monat',
    last_week: 'Letzte Woche',
    this_week: 'Diese Woche',
    this_year: 'Dieses Jahr',
    last_year: 'Letztes Jahr',
    today: 'Heute',
    yesterday: 'Gestern',
    all: 'Alle',
  },
};
