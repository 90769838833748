import {
  CompanyCreationModes,
  CompanyCreationSteps,
  BusinessProfileOption,
  FranchiseOption,
} from '../../../../../views/companies';
import { BusinessType } from '../../../../../services/exports/Constants';

export default {
  title: 'Neues Restaurant',
  sections: {
    new_company: {
      titles: {
        [CompanyCreationModes.demo]: 'Neue Demo',
        [CompanyCreationModes.full]: 'Neue Restaurant',
      },
      steps: {
        [CompanyCreationSteps.CompanyInfo]: 'Restaurant info',
        [CompanyCreationSteps.BusinessInfo]: 'Business info',
        [CompanyCreationSteps.AdditionalLocations]: 'Zusätzliche Restaurants',
      },
    },
    recently_created: 'Kürzlich erstellt',
  },
  form: {
    franchise: {
      label: 'Restaurant erstellen',
      tooltip: 'Ist dieses Restaurant Teil einer bestehenden Kette, oder handelt es sich um eine neue Kette/ein eigenständiges Konzept? (Eigentümer oder Unternehmen werden im 2 Schritt zugeordnet)',
      options: {
        [FranchiseOption.CreateFranchise]: {
          title: 'Ein neues Restaurant/Franchise gründen',
          tooltip: 'Wir fangen bei Null an - kein bestehendes Franchise oder Restaurant',
        },
        [FranchiseOption.UseExistingFranchise]: {
          title: 'Dieses Restaurant zu einem bestehenden Franchise-System hinzufügen',
          tooltip: 'Es gehört zu einer bereits im System befindlichen Franchise',
        },
      },
    },
    restaurants: {
      label: 'Verbundene(s) Restaurant(e)',
    },

    business_profile: {
      label: 'Wer ist der Eigentümer?',
      tooltip: 'Gehört dieses Restaurant zu einem Eigentümer/Firma, der/die bereits in unserem System existiert (z.B. „Luís Pizza GmbH“)? <br/> Wenn ja, wählen Sie das bestehende Eigentümer-/Firmenprofil aus. Andernfalls legen Sie ein neues Eigentümer-/Firmenprofil an.',
      options: {
        [BusinessProfileOption.CreateBusinessProfile]: 'Neues Geschäftsprofil für Eigentümer/Unternehmen erstellenn',
        [BusinessProfileOption.UseExistingBusinessProfile]: 'Vorhandenes Geschäftsprofil des Eigentümers/Unternehmens verwenden',
      },
    },
    business_type: {
      label: 'Art der Tätigkeit',
      placeholder: 'Geschäftstyp auswählen',
      options: {
        [BusinessType.Company]: 'Gastronomischer Betrieb',
        [BusinessType.Individual]: 'Einzelunternehmer',
      },
    },
    display_name: {
      label: 'Restaurant Name',
      placeholder: 'Freundlicher Name',
    },
    legal_name: {
      label: 'Juristischer Name',
      placeholder: 'Juristischer Name',
    },
    owner_phone_number: {
      label: 'Telefonnummer Kontaktperson',
      placeholder: '1785080120',
    },
    owner_email: {
      label: 'E-mail Kontaktperson',
      placeholder: 'E-Mail eingeben',
    },
    password: {
      label: 'Passwort',
      placeholder: 'Gib dein Passwort ein',
    },

    additional_companies: {
      label: 'Weitere Restaurants unter dieser Marke',
      tooltip: 'Gibt es weitere Restaurants unter der gleichen Marke wie {{company}}? <br/> Wenn ja, klicken Sie auf „Restaurant hinzufügen“, um weitere Standorte unter dieser Marke aufzunehmen. Wenn es sich um denselben Eigentümer, aber eine andere Marke handelt, fügen Sie hier nichts hinzu. Legen Sie sie stattdessen separat an.',
    },
  },
  labels: {
    create_demo: 'Demo erstellen',
    create_company: 'Restaurant erstellen',
    business_type: 'Business type',
    display_name: 'Friendly name',
    legal_name: 'Legal name',
    owner_name: 'Owner name',
    address: 'Address',
  },
  fields: {
    name: 'Name',
    created_at: 'Erstellt',
    actions: 'Aktionen',
  },

  buttons: {
    add_restaurant: '+ Add restaurant',
    continue: 'Weiter',
    back: 'Zurück',
    create: 'Restaurant erstellen',
    delete: 'Löschen',
  },
  popups: {
    delete_demo: {
      title: 'Sicher beim Löschen?',
    },
  },
  toasts: {
    company_created: 'Restaurant erfolgreich erstellt',
    failed_to_create_company: 'Restaurant kann nicht erstellt werden',
    failed_to_delete_demo: 'Demo konnte nicht gelöscht werden',
    demo_got_deleted: 'Demo erfolgreich gelöscht',
    failed_to_load_recently_created_companies: 'Kürzlich erstellte Restaurants konnten nicht geladen werden',
  },
};
