export default {
  containers: {
    location_info: 'Standortinformationen',
    attributes: 'Attribute',
    links: 'Links',
  },
  labels: {
    title: 'Titel',
    phone_number: 'Telefonnummer',
    description: 'Beschreibung',
    website: 'Website',
    address: 'Adresse',
    regular_hours: 'Öffnungszeiten',
    page_urls: 'Seiten-URLs',
    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',
    sunday: 'Sonntag',
    twenty_four_hours: '24 Stunden',
    open_24_hours: '24 Stunden geöffnet',
    open_time: 'Öffnungszeit',
    close_time: 'Schließzeit',
    url: 'URL',
    provider_type: 'Anbieter-Typ',
    provider_types: {
      merchant: 'Händler',
      aggregator: 'Aggregator',
    },
    place_action_type: 'Typ',
    is_preferred: 'Bevorzugt',
    place_action_types: {
      appointment: 'Terminbuchung',
      online_appointment: 'Online-Terminbuchung',
      dining_reservation: 'Reservierungs-Link',
      food_ordering: 'Essen bestellen für Lieferung und/oder Abholung',
      food_delivery: 'Lieferungs-Link',
      food_takeout: 'Abholungs-Link',
      shop_online: 'Online-Shoppen, mit Lieferung und/oder Abholung',
    },
    no_links: 'Keine Links für dieses Unternehmen verfügbar',
    url_menu: 'Menü-Link',
    url_reservations: 'Reservierungs-Link',
  },
  placeholders: {
    title: 'Titel',
    phone_number: 'Telefonnummer',
    description: 'Beschreibung',
    website: 'Website',
    address: 'Adresse',
    regular_hours: 'Öffnungszeiten',
    page_urls: 'Seiten-URLs',
    address_line_1: 'Adresszeile 1',
    postal_code: 'Postleitzahl',
    locality: 'Ort',
    region_code: 'Regioncode',
    language_code: 'Sprachcode',
    select_time: 'Zeit auswählen',
    url: 'URL',
  },
  buttons: {
    submit: 'Absenden',
    add: 'Hinzufügen',
    delete: 'Löschen',
    generate_description: 'KI Beschreibung generieren',
  },
  validation: {
    required: 'Dieses Feld ist erforderlich',
    invalid_url: 'Ungültige URL',
  },
  toasts: {

  },
};
