export default {
  title: {
    regular: 'Reguläre Modifikatoren für Produkte',
    meal: 'Erstelle dein Menü-Deal-Element',
    upsell: 'Erstelle deine Upsell-Gruppe',
  },
  description: {
    regular: 'Wähle Modifikatoren aus, die in die neue Modifikatorgruppe aufgenommen werden sollen, falls sie bereits in einer anderen Modifikatorgruppe existieren. Du kannst auch mit der Erstellung der Modifikatorgruppe fortfahren und später neue Modifikatoren hinzufügen.',
    meal: "Wähle Produkte aus, die in dein Menü-Deal-Element aufgenommen werden sollen. Falls die Produkte noch nicht existieren, erstelle sie bitte auf der Seite 'Menüs verwalten'. Stelle sicher, dass du das Menü-Deal-Element unter den richtigen Produkten im Abschnitt 'Modifikatoren' des Produkt-Builders anfügst.",
    upsell: "Wähle Produkte aus, die in deine Upsell-Gruppe aufgenommen werden sollen. Falls die Produkte noch nicht existieren, erstelle sie bitte auf der Seite 'Menüs verwalten'. Stelle sicher, dass du das Upsell-Element unter den richtigen Produkten im Abschnitt 'Modifikatoren' des Produkt-Builders anfügst.",
  },
  fields: {
    name: {
      label: 'Name',
      placeholder: 'Modifizierergruppe-Name hinzufügen',
    },
    max_quantity: {
      label: 'Maximale Menge',
      placeholder: 'Maximale Anzahl von Modifizierer',
    },
    is_required: {
      label: 'Erforderliche Zusatz-Modifizierer',
    },
  },
  labels: {
    visible: 'Sichtbar',
    hidden: 'Versteckt',
    multiply: 'Multiplizierbar',
  },
  buttons: {
    save: 'Speichern',
    close: 'Schliessen',
  },
  toasts: {
    modifier_group_got_created: 'Modifizierergruppe wurde erstellt',
    modifier_group_got_updated: 'Modifizierergruppe wurde aktualisiert',
  },
};
