export default {
  tabs: {
    general: 'Allgemein',
    restaurant_details: 'Restaurantdetails',
    external_delivery: 'Externe Lieferung',
  },
  date_range: {
    this_month: 'Diesen Monat',
    last_month: 'Letzten Monat',
    last_week: 'Letzte Woche',
    last_year: 'Letztes Jahr',
    this_week: 'Diese Woche',
    this_year: 'Dieses Jahr',
    today: 'Heute',
    yesterday: 'Gestern',
    all: 'Alle',
  },
};
