import { GOOGLE_ADS_CAMPAIGN_STATUS } from '../../../../../../../services/exports/Constants';

export default {
  buttons: {
    back: 'Back',
  },
  metrics: {
    impressions: {
      label: 'Impressions',
      description: 'The number of times your ad was shown on Google search results or partner websites.',
    },
    clicks: {
      label: 'Clicks',
      description: 'The number of times people clicked on your ad.',
    },
    budget_spent: {
      label: 'Budget Spent',
      description: 'The total amount of money spent on this campaign.',
    },
    absolute_top_impression_percentage: {
      label: 'Absolute Top Impression %',
      description: 'The percentage of times your ad appeared at the very top of Google search results.',
    },
    calls: {
      label: 'Calls',
      description: 'The total number of phone calls received from your ad.',
    },
    phone_impressions: {
      label: 'Phone Impressions',
      description: 'The number of times your phone number was displayed in the ad.',
    },
    phone_through_rate: {
      label: 'Phone Through Rate',
      description: 'The percentage of phone impressions that resulted in actual calls (Calls ÷ Phone Impressions).',
    },
    directions: {
      label: 'Directions',
      description: 'The number of times users clicked to get directions to your business location.',
    },
    daily_budget: {
      label: 'Daily Budget',
      description: 'The maximum amount set to spend per day on this campaign.',
    },
    status: {
      label: 'Status',
      description: 'The current running state of your campaign.',
    },
    ctr: {
      label: 'Click-Through Rate',
      description: 'The percentage of ad views that resulted in clicks (Clicks ÷ Impressions).',
    },
    average_cpc: {
      label: 'Average Cost Per Click',
      description: 'The average amount you pay each time someone clicks your ad (Total Cost ÷ Total Clicks).',
    },
    conversions_from_interactions_rate: {
      label: 'Conversion Rate',
      description: 'The percentage of ad interactions that led to a conversion.',
    },
    start_date: {
      label: 'Start Date',
      description: 'The date the campaign started.',
    },
  },
  status: {
    [GOOGLE_ADS_CAMPAIGN_STATUS.UNSPECIFIED]: 'Not Specified',
    [GOOGLE_ADS_CAMPAIGN_STATUS.ENABLED]: 'Enabled',
    [GOOGLE_ADS_CAMPAIGN_STATUS.PAUSED]: 'Paused',
    [GOOGLE_ADS_CAMPAIGN_STATUS.UNKNOWN]: 'Unknown',
    [GOOGLE_ADS_CAMPAIGN_STATUS.REMOVED]: 'Removed',
  },
  error_messages: {
    loading_failed: 'Failed to load campaign details. Please try again.',
  },
};
