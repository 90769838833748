export default {
  fields: {
    demo: {
      label: 'Create Demo',
    },
    ai: {
      label: 'Ai Optimized',
    },
    restaurant_type: {
      label: 'Select restaurant type',
      types: {
        Pizza: 'Pizza 🍕',
        PizzaDelivery: 'Pizza Delivery 🍕🚗',
        PizzaNeapolitan: 'Neapolitan Pizza 🍕',
        Italian: 'Italian 🇮🇹',
        Burger: 'Burger 🍔',
        Turkish: 'Turkish 🇹🇷',
        Mexican: 'Mexican 🌮',
        Sushi: 'Sushi 🍱',
        Japanese: 'Japanese 🇯🇵',
        Vietnamese: 'Vietnamese 🇻🇳',
        Thai: 'Thai 🇹🇭',
        Asian: 'Asian 🍜',
        Indian: 'Indian 🥘',
        Bowls: 'Bowls 🥣',
        Vegan: 'Vegan 🥑',
        Greek: 'Greek 🏛️',
        Cafe: 'Café ☕️',
        Brunch: 'Brunch 🍵',
        Oriental: 'Oriental / Middle Eastern 🧆',
        Spanish: 'Spanish / Tapas 🇪🇸',
        Persian: 'Persian 🫖',
      },
    },
    plugins: {
      reservations: 'Reservations',
      catering: 'Catering',
      events: 'Events',
    },
    quarter: {
      label: 'District',
      address_placeholder: 'Enter address',
      quarter_placeholder: 'Enter district',
    },
    delivery_areas: {
      label: 'What areas do you serve in the city?',
      city_placeholder: 'Enter city',
      district_placeholder: 'District 1, District 2, District 3',
    },
    best_dishes: {
      label: 'What are your top 3 dishes?',
      placeholder: 'Enter dishes (Chicken burger, Cheese burger, etc.)',
    },
    dietary_attributes: {
      label: 'Do you have options for people who want to eat:',
      placeholder: 'Please select dietary attributes',
      types: {
        vegetarian: 'Vegetarian',
        vegan: 'Vegan',
        halal: 'Halal',
        glutenFree: 'Gluten Free',
        dairyFree: 'Dairy Free',
        spicy: 'Spicy',
      },
    },
    family_owned: {
      label: 'Family owned?',
      yes: 'Yes',
      no: 'No',
    },
    business_founding_year: {
      label: 'When was the restaurant started (what year)?',
    },
    business_owners: {
      label: 'Business owners',
      placeholder: 'Enter business owner',
    },
    notes: {
      label: 'Add additional notes (optional)',
      placeholder: 'Example community oriented restaurant, initiatives, couple owned...',
    },
    about_us: {
      title: 'About Us (Preview)',
    },
  },
  sections: {
    plugins: 'Plugins',
    company_info: 'Generate Company Info',
  },
  buttons: {
    find_district: 'Find district',
    generate: 'Generate',
    products: 'Products',
    add: 'Add',
  },
};
