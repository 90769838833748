import { DeliveryMode } from '../../../../../services/exports/Constants';

export default {
  sections: {
    state: {
      title: 'State',
      fields: {
        delivery_visible: {
          label: 'Lieferung sichtbar',
        },
        has_delivery: {
          label: 'Lieferung verfügbar',
        },
        delivery_enabled: {
          label: 'Lieferung live',
        },
      },
    },
    delivery_mode: {
      title: 'Anbieter der Lieferung',
      fields: {
        delivery_mode: {
          options: {
            [DeliveryMode.Internal]: 'Restaurant-Lieferflotte',
            [DeliveryMode.External]: 'Externe Lieferflotte ({{provider}})',
          },
        },
        external_delivery_pickup_note: {
          label: 'Abholschein für Fahrer',
          placeholder: 'Zusätzliche nützliche Informationen für den Fahrer (z. B. wie er den Eingang findet, usw.)',
        },
      },
    },
    scheduled_orders: {
      title: 'Geplante Bestellungen',
      fields: {
        has_scheduled_orders: {
          label: 'Erlaubst du deinen Kunden im Voraus für geplante Wunschzeiten zu bestellen?',
        },
        min_schedule_ahead_time_delivery: {
          label: 'Mindestzeit im Voraus für Bestellungen zur <strong>Lieferung</strong>',
          placeholder: '60',
        },
        last_order_gap_delivery: {
          label: ('Letzter möglicher Zeitpunkt für die Bestellung zur <strong>Lieferung</strong> (Minuten vor Geschäftsschluss)'),
          option: '{{value}} min',
        },
      },
    },
    delivery_info: {
      title: 'Lieferung Info',
      fields: {
        average_order_delivery_time_min: {
          label: 'Lieferungszeit in Minuten',
          placeholder: 'Lieferungszeit in Minuten',
        },
        average_order_delivery_time_max: {
          label: 'bis',
          placeholder: 'Optional',
        },
      },
    },
    delivery_zones: {
      title: 'Lieferzonen',
    },
  },
  buttons: {
    save: 'Speichern',
  },
  toasts: {
    at_least_one_order_method_required: 'Mindestens 1 Bestellmethode (geplant oder asap) muss aktiviert sein. Beide können nicht gleichzeitig deaktiviert werden.',
  },
};
