export default {
  title: 'Aktualisiere die Abgabezeit',
  labels: {
    dropoff_time: 'Abgabezeit',
    confirmed: 'Bestätigte Zeit:',
    new_time: 'Neue Zeit:',
  },
  buttons: {
    save: 'Speichern',
  },
  toasts: {
    failed_to_save: 'Änderungen konnten nicht gespeichert werden',
  },
};
