export default {
  title: 'Deliverect',
  form: {
    channel_link_id: {
      label: 'Channel Link ID: ',
    },
    store_id: {
      label: 'EXTERNAL LOCATION ID: ',
    },
  },
  buttons: {
    save: 'Speichern',
  },
  toasts: {
    error: 'Änderungen konnten nicht gespeichert werden',
    success: 'Änderungen wurden gespeichert',
  },
  instructions: {
    title: 'Einrichtungsanleitung:',
  },
};
