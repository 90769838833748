import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../base-query';
import { serializeQueryArgs } from '../serialize-query-args';

export const deliveriesApiSlice = createApi({
  reducerPath: 'deliveriesApiSlice',
  baseQuery,
  endpoints: (builder) => ({
    updateDelivery: builder.mutation({
      query: ({ apiManager, id, data }) => apiManager.update(id, data),
      invalidatesTags: ['deliveries'],
    }),
  }),
  serializeQueryArgs,
});

export const { useUpdateDeliveryMutation } = deliveriesApiSlice;
