export default {
  title: 'New franchise',
  tabs: {
    general: 'General',
    branding: 'Branding',
    scripts: 'Scripts',
    tracking: 'Tracking',
  },
  fields: {
    name: {
      label: 'Name',
      placeholder: 'Franchise name',
    },
    subdomain: {
      label: 'Subdomain',
      placeholder: 'Franchise subdomain',
    },
    lieferando_slug: {
      label: 'Lieferando slug',
      placeholder: 'Last part of restaurant URL in Lieferando',
    },
    primary_color: {
      label: 'Primary color',
    },
    logo: {
      label: 'Logo',
    },
    image: {
      label: 'Image',
    },
    companies: {
      label: 'Restaurants',
    },
    noscripts: {
      description: 'Inject code immediately after opening body',
    },
    facebook_pixel: {
      label: 'Facebook Pixel',
    },
    facebook_conversion_api_access_token: {
      label: 'Facebook Conversion Access Token',
    },
  },
  buttons: {
    save: 'Save',
    close: 'Close',
    sync_from_lieferando: 'Sync from Lieferando',
  },
  toasts: {
    changes_saved_successfully: 'Changes have been successfully saved!',
    failed_to_update_company_slug: 'Failed to update slug for {{name}}',
    failed_to_sync_lieferando: 'Failed to sync details from Lieferando',
    successfully_synced_lieferando: 'Data successfully synced from Lieferando',
  },
};
