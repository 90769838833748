export default {
  title: {
    regular: 'Regular modifiers for products',
    meal: 'Create your meal deal item',
    upsell: 'Create your upsell group',
  },
  description: {
    regular: 'Select modifiers that will be included in the new modifier group if they already exist in other modifier group. You can also proceed with modifier group creation and add new modifiers later.',
    meal: 'Select products that will be included in your meal deal item. If products do not exist yet, please create them on the "Manage menus" page. Please make sure to attach the meal-deal item under proper products in "Modifiers" section of the product builder.',
    upsell: 'Select products that will be included in your upsell group. If products do not exist yet, please create them on the "Manage menus" page. Please make sure to attach the upsell item under proper products in "Modifiers" section of the product builder.',
  },
  fields: {
    name: {
      label: 'Name',
      placeholder: 'Enter modifier group name',
    },
    max_quantity: {
      label: 'Max quantity',
      placeholder: 'Max modifiers quantity',
    },
    is_required: {
      label: 'Required',
    },
  },
  labels: {
    visible: 'Visible',
    hidden: 'Hidden',
    multiply: 'Multipliable',
  },
  buttons: {
    save: 'Save',
    close: 'Close',
  },
  toasts: {
    modifier_group_got_created: 'Modifier group got created',
    modifier_group_got_updated: 'Modifier group got updated',
  },
};
