export default {
  tabs: {
    general: 'General',
    restaurant_details: 'Restaurant details',
    external_delivery: 'External delivery',
  },
  date_range: {
    this_month: 'This Month',
    last_month: 'Last Month',
    last_week: 'Last Week',
    last_year: 'Last Year',
    this_week: 'This Week',
    this_year: 'This Year',
    today: 'Today',
    yesterday: 'Yesterday',
    all: 'All',
  },
};
