import React, { useEffect, useState } from 'react';
import { CFade } from '@coreui/react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Checkbox, Popover } from 'antd';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { collect } from 'collect.js';
import ConfirmInformationIllustration from '../../resources/assets/illustrations/ConfirmInformationIllustration';
import PendingVerification from './status/PendingVerification';
import CompleteVerification from './status/CompleteVerification';
import useVerificationStatus from '../../hooks/verification/useVerificationStatus';
import useApiClient from '../../hooks/api/useApiClient';
import LoadingIndicator from '../LoadingIndicator';
import { STRIPE_VERIFICATION_STATUS } from '../../services/exports/Constants';
import { BusinessProfileUpdateStrategy } from '../../providers/BusinessProfileProvider';

export default function InfoSubmissionStep(props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Verification:InfoSubmissionStep' });

  const { moveForward } = props;

  const { company } = useSelector((state) => state.currentCompany);
  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);

  const { CurrentCompanyManager, BusinessProfilesManager } = useApiClient();
  const { isPending, isComplete } = useVerificationStatus();

  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  useEffect(
    () => mount(),
    [],
  );

  const mount = async () => {
    setTimeout(
      () => CurrentCompanyManager.get()
        .then((response) => response.success
          && response.data.stripe_status === STRIPE_VERIFICATION_STATUS.APPROVED
          && moveForward())
        .finally(() => setMounted(true)),
      2000,
    );
  };

  const onProceed = async () => {
    setLoading(true);
    const { success } = await BusinessProfilesManager.update({
      terms_accepted: termsAccepted,
      update_strategy: BusinessProfileUpdateStrategy.All,
    });
    setLoading(false);

    if (!success) {
      return toast.error(t('toasts.something_went_wrong'));
    }

    return moveForward();
  };

  if (isPending) {
    return <PendingVerification />;
  }

  if (isComplete) {
    return <CompleteVerification />;
  }

  return (
    <CFade>
      {!mounted && (
        <div className="tw-my-small">
          <LoadingIndicator />
        </div>
      )}
      {mounted && (
        <>
          {company.business_profile.stripe_tos_acceptance ? (
            <>
              <p className="tw-mb-mini">{t('labels.stripe_requirements')}</p>
              {company.merchant.stripe_requirements.currently_due.map((requirement) => (
                <Popover
                  key={`requirement-${requirement}`}
                  title={t('labels.errors')}
                  content={(
                    <ul>
                      {collect(company.merchant.stripe_requirements.errors).where('requirement', requirement).map((error) => (
                        <li
                          key={`requirement-${requirement}-error-${error.code}`}
                        >
                          {error.reason}
                        </li>
                      )).toArray()}
                    </ul>
                  )}
                  overlayClassName="lg:tw-max-w-1/2"
                >
                  {requirement}
                  {' '}
                  <br />
                </Popover>
              ))}
            </>
          ) : (
            <div className="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center tw-mx-auto">
              <div
                className="tw-relative lg:tw-w-5/12 tw-text-center tw-max-w-lg tw-mx-auto lg:tw-max-w-none lg:tw-text-left"
              >
                <h5>{t('title')}</h5>
                <p className="tw-mt-small tw-text-gray-default">{t('description')}</p>
                <Checkbox
                  checked={termsAccepted}
                  className="tw-mt-small"
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                >
                  <Trans
                    t={t}
                    components={{
                      FoodAmigosTerms: <a
                        href={serviceProvider.participation_agreement_url}
                        target="_blank"
                        rel="noreferrer noopener"
                      />,
                      StripeTerms: <a
                        href="https://stripe.com/legal/connect-account"
                        target="_blank"
                        rel="noreferrer noopener"
                      />,
                    }}
                  >
                    form.terms_acceptance.label
                  </Trans>
                </Checkbox>
                <Button
                  type="primary"
                  className="tw-mt-small"
                  loading={loading}
                  disabled={!termsAccepted}
                  onClick={onProceed}
                >
                  {t('buttons.proceed')}
                </Button>
              </div>
              <div
                className="tw-relative lg:tw-mt-0 tw-flex-1 tw-flex tw-flex-col tw-justify-center tw-items-center lg:tw-items-end lg:tw-p-small lg:tw-self-end tw-order-first lg:tw-order-last"
              >
                <ConfirmInformationIllustration />
              </div>
            </div>
          )}
        </>
      )}
    </CFade>
  );
}
