import React from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { ToastContainer } from 'react-toastify';
import { CookiesProvider } from 'react-cookie';
import AuthProvider from './providers/AuthProvider';
import Router from './navigation/Router';
import InitialDataProvider from './providers/InitialDataProvider';
import HelpersProvider from './providers/HelpersProvider';
import AntConfigProvider from './providers/AntConfigProvider';
import ScreenTypeProvider from './providers/ScreenTypeProvider';
import ThemeProvider from './providers/ThemeProvider';
import MetaInfo from './components/meta/MetaInfo';
import RNWebViewProvider from './providers/RNWebViewProvider';
import SmartAppBanner from './components/banner/SmartAppBanner';
import RNNotificationProvider from './providers/RNNotificationProvider';
import AppStateProvider from './providers/AppStateProvider';
import BusinessProfileProvider from './providers/BusinessProfileProvider';
import OCCAgentGate from './navigation/middleware/OCCAgentGate';

const mapLibraries = ['places', 'geometry', 'drawing'];

export default function App() {
  useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    libraries: mapLibraries,
  });

  return (
    <CookiesProvider>
      <AuthProvider>
        <OCCAgentGate>
          <ThemeProvider>
            <AntConfigProvider>
              <ScreenTypeProvider>
                <SmartAppBanner />
                <RNWebViewProvider>
                  <InitialDataProvider>
                    <AppStateProvider>
                      <RNNotificationProvider>
                        <HelpersProvider>
                          <BusinessProfileProvider>
                            <MetaInfo />
                            <Router />
                            <ToastContainer
                              position="top-right"
                              autoClose={3000}
                              hideProgressBar
                              newestOnTop
                              pauseOnHover={false}
                              className="px-1 z-max"
                              toastClassName="my-2 rounded-lg"
                            />
                          </BusinessProfileProvider>
                        </HelpersProvider>
                      </RNNotificationProvider>
                    </AppStateProvider>
                  </InitialDataProvider>
                </RNWebViewProvider>
              </ScreenTypeProvider>
            </AntConfigProvider>
          </ThemeProvider>
        </OCCAgentGate>
      </AuthProvider>
    </CookiesProvider>
  );
}
