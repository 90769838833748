import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../base-query';
import { serializeQueryArgs } from '../serialize-query-args';

export const modifiersApiSlice = createApi({
  reducerPath: 'modifiersApiSlice',
  baseQuery,
  endpoints: (builder) => ({
    getModifiers: builder.query({
      query: ({ apiManager, ...rest }) => apiManager.get(rest),
    }),
  }),
  serializeQueryArgs,
});

export const { useGetModifiersQuery, useLazyGetModifiersQuery } = modifiersApiSlice;
