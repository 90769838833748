export default {
  fields: {
    sales: {
      label: 'Umsatz',
    },
    average_order_value: {
      label: 'Durchschnittlicher Bestellwert',
    },
    savings: {
      label: 'Ersparnisse bei Drittanbieter-Gebühren',
    },
    reservations: {
      label: 'Reservierungen',
    },
    crossed_700_sales: {
      label: 'Über 700,00 € Umsatz',
    },
    sales_from_email: {
      label: 'Umsatz durch E-Mail',
    },
    sales_from_google_seo: {
      label: 'Umsatz durch Google SEO',
    },
    sales_from_google_business_profile: {
      label: 'Umsatz durch Google Business',
    },
    sales_from_google_ads: {
      label: 'Umsatz durch Google Ads',
    },
    sales_from_flyers: {
      label: 'Umsatz durch Flyer',
    },
    sales_from_social_media: {
      label: 'Umsatz durch Social Media',
    },
    sales_from_facebook_ads: {
      label: 'Umsatz durch Facebook Ads',
    },
    tips: {
      label: 'Trinkgeld',
    },
    new_customers: {
      label: 'Neue Kunden',
    },
    returning_customers: {
      label: 'Wiederkehrende Kunden',
    },
    new_visitors: {
      label: 'Neue Besucher',
    },
    new_followers: {
      label: 'Neue Follower',
    },
    total_followers: {
      label: 'Gesamtanzahl Follower',
    },
    delivered_messages: {
      label: 'Zugestellte Nachrichten',
    },
    average_open_rate: {
      label: 'Durchschnittliche Öffnungsrate',
    },
    average_click_rate: {
      label: 'Durchschnittliche Klickrate',
    },
    website_visitors_from_email: {
      label: 'Website-Besucher durch E-Mail',
    },
    sales_from_google_business: {
      label: 'Umsatz durch Google Business',
    },
    total_reservations: {
      label: 'Reservierungen',
    },
    website_visitors_from_google_seo: {
      label: 'Website-Klicks durch Google SEO',
    },
    website_clicks_from_google_business: {
      label: 'Website-Klicks durch Google Business',
    },
    website_visitors_from_google_ads: {
      label: 'Website-Klicks durch Google Ads',
    },
    website_visitors_from_social_media: {
      label: 'Website-Klicks durch Social Media',
    },
    calls_from_google_ads: {
      label: 'Anrufe durch Google Ads',
    },
    directions_from_google_ads: {
      label: 'Wegbeschreibungen durch Google Ads',
    },
    google_seo_orders_count: {
      label: 'Google SEO',
    },
    google_business_orders_count: {
      label: 'Google Business',
    },
    google_ads_orders_count: {
      label: 'Google Ads',
    },
    facebook_ads_orders_count: {
      label: 'Facebook Ads',
    },
    social_media_orders_count: {
      label: 'Social Media',
    },
    flyer_orders_count: {
      label: 'Flyer',
    },
    email_orders_count: {
      label: 'E-Mails',
    },
    other_orders_count: {
      label: 'Andere',
    },
    gmv: {
      label: 'Bruttowarenwert (GMV)',
    },
    revenue: {
      label: 'Einnahmen',
    },
    revenue_subscription: {
      label: 'Einnahmen aus Abonnements',
    },
    revenue_service_fee: {
      label: 'Einnahmen aus Servicegebühren',
    },
    revenue_service_charges: {
      label: 'Einnahmen aus Servicepauschalen',
    },
    processing_fees: {
      label: 'Bearbeitungsgebühren',
    },
    processing_fees_stripe: {
      label: 'Bearbeitungsgebühren (Stripe)',
    },
    processing_fees_paypal: {
      label: 'Bearbeitungsgebühren (PayPal)',
    },
    rejected_orders: {
      label: 'Abgelehnte Bestellungen',
    },
    rejected_orders_amount: {
      label: 'Betrag abgelehnter Bestellungen',
    },
    rejected_orders_count: {
      label: 'Anzahl abgelehnter Bestellungen',
    },
    rejected_orders_rate: {
      label: 'Ablehnungsrate der Bestellungen',
    },
    rejected_orders_rate_breakdown: {
      label: 'Aufschlüsselung der Ablehnungsrate',
    },
    orders: {
      label: 'Bestellungen',
    },
    total_orders: {
      label: 'Gesamtanzahl Bestellungen',
    },
    pickup_orders_rate: {
      label: 'Abholrate der Bestellungen',
    },
    delivery_orders_rate: {
      label: 'Lieferungsrate der Bestellungen',
    },
    other_orders_rate: {
      label: 'Sonstige Bestellrate',
    },
    customers: {
      label: 'Gesamtanzahl Kunden',
    },
    customers_auth_state: {
      label: 'Authentifizierungsstatus der Kunden',
    },
    website_reservations: {
      label: 'Reservierungen über die Website',
    },
    google_reservations: {
      label: 'Reservierungen über Google',
    },
    google_reviews: {
      label: 'Google-Bewertungen',
    },
    loyalty_sign_ups: {
      label: 'Loyalty-Anmeldungen',
    },
    companies: {
      label: 'Unternehmen',
    },
    live_restaurants: {
      label: 'Live-Restaurants',
    },
    live_restaurants_percentage: {
      label: 'Prozentualer Anteil der Live-Restaurants',
    },
    crossed_sales_level_this_month: {
      label: 'Überschrittenes Verkaufsniveau diesen Monat',
    },
    crossed_sales_level_total: {
      label: 'Gesamtanzahl überschrittener Verkaufsniveaus',
    },
    crossed_sales_level_percentage: {
      label: 'Prozentualer Anteil überschrittener Verkaufsniveaus',
    },
    has_delivery: {
      label: 'Eigene Lieferung',
    },
    has_only_pickup: {
      label: 'Nur Abholung',
    },
    has_storefront: {
      label: 'Hat Schaufenster',
    },
    has_storefront_percentage: {
      label: 'Prozentualer Anteil der Schaufenster',
    },
    use_external_delivery: {
      label: 'Externe Lieferung',
    },
    crossed_monthly_sales_threshold_0: {
      label: 'Monatliche Verkäufe: 0 - 699',
    },
    crossed_monthly_sales_threshold_700: {
      label: 'Monatliche Verkäufe: 700+',
    },
    crossed_monthly_sales_threshold_2500: {
      label: 'Monatliche Verkäufe: 2500+',
    },
    crossed_monthly_sales_threshold_10000: {
      label: 'Monatliche Verkäufe: 10000+',
    },
    restaurant_partners: {
      label: 'Restaurant-Partner',
    },
    registered_restaurants: {
      label: 'Registrierte Restaurants',
    },
    use_reservations: {
      label: 'Nutzt Reservierungen',
    },
    number: {
      label: 'Nummer',
    },
    restaurants_percentage: {
      label: 'Prozentualer Anteil der Restaurants',
    },
    crossed_sales_level: {
      label: 'Überschrittenes Verkaufsniveau',
    },
    order_method: {
      label: 'Bestellmethode',
    },
    own_delivery: {
      label: 'Eigene Lieferung',
    },
    only_pickup: {
      label: 'Nur Abholung',
    },
    storefront: {
      label: 'Website',
    },
    sales_tiers: {
      label: 'Verkaufsstufen',
    },
    first_delivery: {
      label: 'Uber',
    },
    wolt_drive: {
      label: 'Wolt',
    },
    delivery_quotes_count: {
      label: 'Anzahl der Lieferangebote',
    },
    deliveries_count: {
      label: 'Anzahl der Lieferungen',
    },
    delivery_conversion: {
      label: 'Lieferkonversionsrate',
    },
    delivery_quotes_provider_breakdown: {
      label: 'Aufschlüsselung der Lieferangebote nach Anbieter',
    },
    deliveries_provider_breakdown: {
      label: 'Aufschlüsselung der Lieferungen nach Anbieter',
    },
    total_delivered: {
      label: 'Zugestellte Nachrichten',
    },
    website_visitors_from_google_business_profile: {
      label: 'Website Clicks von Google Business',
    },
    google_business_impressions: {
      label: 'Impressionen von Google Business',
    },
    google_business_website_clicks: {
      label: 'Website-Klicks von Google Business',
    },
    google_ads_impressions: {
      label: 'Impressionen von Google Ads',
    },
    google_ads_clicks: {
      label: 'Klicks von Google Ads',
    },
    google_ads_phone_calls: {
      label: 'Anrufe von Google Ads',
    },
    google_ads_directions: {
      label: 'Wegbeschreibungen von Google Ads',
    },
    website_visits: {
      label: 'Website-Besuche',
    },
    website_visitors: {
      label: 'Website-Besucher',
    },
    companies_with_activated_reservations: {
      label: 'Restaurants mit aktivierten Reservierungen',
    },
    online_orders_count: {
      label: 'Online-Bestellungen',
    },
    cash_orders_count: {
      label: 'Bargeldbestellungen',
    },
    cash_sales: {
      label: 'Barverkäufe',
    },
    online_sales: {
      label: 'Online-Verkäufe',
    },
  },
};
