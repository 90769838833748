import React from 'react';
import {
  CModalFooter,
} from '@coreui/react';
import tw from 'twin.macro';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { Drawer } from 'antd';
import OrderLineItem from '../../components/orders/OrderLineItem';
import {
  COUPON_TYPES, DeliveryProvider, FULFILLMENT_STATUSES,
  ORDER_METHODS,
  PAYMENT_METHODS, ROOM_SERVICE_LOCATION_TYPES,
} from '../../services/exports/Constants';
import CreditCardIcon from '../../resources/assets/icons/CreditCardIcon';
import PickupIcon from '../../resources/assets/icons/PickupIcon';
import DeliveryIcon from '../../resources/assets/icons/DeliveryIcon';
import FeedbackLabel from '../../components/label/FeedbackLabel';
import StarCircleIcon from '../../resources/assets/icons/StarCircleIcon';
import CashIcon from '../../resources/assets/icons/CashIcon';
import useHelpers from '../../hooks/context/useHelpers';
import RoomServiceIcon from '../../resources/assets/icons/RoomServiceIcon';
import TableServiceIcon from '../../resources/assets/icons/TableServiceIcon';
import DeliveryTimeDifferenceBadge from '../../components/orders/DeliveryTimeDifferenceBadge';
import CarrierIcon from '../../resources/assets/icons/CarrierIcon';
import PhoneIcon from '../../resources/assets/icons/PhoneIcon';
import useScreenType from '../../hooks/context/useScreenType';
import DeliveryProviderIcon from '../../components/delivery/DeliveryProviderIcon';

export default function OrderDetailsModal({
  show = false,
  toggleModal,
  data,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Modals:Order:OrderDetailsModal' });

  const { formatCurrency, formatTime, formatDateTime } = useHelpers();
  const { isDesktop } = useScreenType();

  function renderFooter() {
    return (
      <CModalFooter>
        <div className="w-100 website-text secondary font-bold text-center pt-3">
          {t(`fulfillment_statuses.${data.fulfillment.status}`, t('fulfillment_statuses.default'))}
        </div>
      </CModalFooter>
    );
  }

  const renderPaymentInfo = () => {
    if (data.payment.payment_method_id === PAYMENT_METHODS.CASH) {
      return (
        <div className="mt-4 website-text d-flex">
          <CashIcon className="my-auto h-6 w-6" />
          <div className="my-auto ml-2">{t(data.company.overwrite_cash_payments_to_invoice ? 'labels.invoice_payment' : 'labels.paid_in_cash')}</div>
        </div>
      );
    }

    return (
      <div className="mt-4 website-text-mini secondary d-flex">
        <CreditCardIcon width={28} height={20} className="card-icon" />
        <div className="mt-auto ml-2">{t('labels.customer_paid_online')}</div>
      </div>
    );
  };

  const renderOrderMethodIcon = () => {
    if (data.method === ORDER_METHODS.delivery && data.delivery_provider !== DeliveryProvider.Standalone) {
      return (
        <DeliveryProviderIcon
          provider={data.delivery_provider}
          className="h-12 w-12"
        />
      );
    }

    const icon = ({
      [ORDER_METHODS.room_service]: data.room_service_location?.type === ROOM_SERVICE_LOCATION_TYPES.room_service
        ? <RoomServiceIcon className="h-8 w-8 m-auto" />
        : <TableServiceIcon className="h-8 w-8 m-auto" />,
      [ORDER_METHODS.pickup]: <PickupIcon className="h-12 w-12 m-auto" />,
      [ORDER_METHODS.delivery]: <DeliveryIcon className="h-12 w-12 m-auto" />,
    })[data.method];

    return (
      <div className="h-14 w-14 bg-gray-800 rounded-full d-flex">{icon}</div>
    );
  };

  const renderOrderMethodLabel = () => ({
    [ORDER_METHODS.room_service]: t(`order_methods.${data.room_service_location?.type}`),
    [ORDER_METHODS.pickup]: t(`order_methods.${data.method}`),
    [ORDER_METHODS.delivery]: t(`order_methods.${data.method}`),
  })[data.method];

  return (
    <Drawer
      id="order-details-modal"
      open={show}
      alignment="center"
      scrollable
      size="xl"
      width={window.innerWidth * (isDesktop ? 0.5 : 1)}
      onClose={toggleModal}
      footer={renderFooter()}
      title={t('title')}
      classNames={{
        body: '!tw-overflow-x-hidden',
      }}
    >
      <InfoContainer>
        <LeftInfoSection>
          <div>
            <h5 className="mr-2">
              #
              {data.fulfillment.number}
            </h5>
            <div className="website-text font-bold">
              {data?.customer_first_name}
              {' '}
              {data?.customer_last_name}
            </div>
          </div>
          {data?.method === ORDER_METHODS.delivery && (
            <>
              <div className="website-text mt-1">
                {data?.delivery_street_name}
                {' '}
                {data?.delivery_street_number}
                ,
              </div>
              <div className="website-text mt-1">
                {data?.delivery_zip_code}
                {' '}
                {data?.delivery_city}
              </div>
              {data?.delivery_doorbell_name && <div className="website-text mt-1">{data?.delivery_doorbell_name}</div>}
              {data?.delivery_company_name && <div className="website-text mt-1">{data?.delivery_company_name}</div>}
            </>
          )}
          {data?.method === ORDER_METHODS.room_service && (
            <>
              <div className="website-text mt-1">{data.room_service_location.name}</div>
              <div className="website-text mt-1">
                {data.room_service_location.street_name}
                {' '}
                {data.room_service_location.street_number}
              </div>
              <div className="website-text mt-1">
                {t(`room_service_location_types.${data.room_service_location.type}.details_label`)}
                {': '}
                {data.room_service_details}
              </div>
            </>
          )}
          <div className="website-text mt-1">
            <b>
              {t('labels.phone')}
              :
            </b>
            {' '}
            {data?.customer_phone_number}
          </div>
          {data?.customer_email ? (
            <div className="website-text mt-1">
              <b>
                {t('labels.email')}
                :
              </b>
              {' '}
              {data?.customer_email}
            </div>
          ) : null}
        </LeftInfoSection>
        <RightInfoSection>
          <div>
            <div className="d-flex justify-content-center">
              {renderOrderMethodIcon()}
            </div>
            <div className="website-text-mini secondary w-100 text-center">
              {renderOrderMethodLabel()}
            </div>
            <div
              className="website-text font-bold text-white w-100 text-center bg-black mt-1"
            >
              {data?.scheduled_for
                ? formatTime(data.fulfillment.estimated_pickup_time)
                : 'ASAP'}
            </div>
            <DeliveryTimeDifferenceBadge
              initial={data.delivery?.initial_pickup_estimate}
              actual={data.delivery?.pickup_estimate}
              className="w-full mt-2"
            />
          </div>
        </RightInfoSection>
      </InfoContainer>
      {data?.coupon_type === COUPON_TYPES.REWARD && (
        <FeedbackLabel
          message={(
            <div className="d-flex">
              <StarCircleIcon className="mr-2 my-auto" />
              <div className="website-text my-auto">{t('labels.points_used')}</div>
            </div>
          )}
          type="notice"
          className="mt-3"
        />
      )}
      {data.scheduled_for && data.fulfillment.status !== FULFILLMENT_STATUSES.preparing && (
        <Schedule isReminder={data.fulfillment.status === FULFILLMENT_STATUSES.accepted}>
          <h5 className="text-white text-center">
            {t('schedule.header', {
              date: moment(data.fulfillment.estimated_pickup_time).isSame(moment(), 'day')
                ? t('labels.today')
                : formatDateTime(data.fulfillment.estimated_pickup_time, { format: 'dddd, DD MMM' }),
              time: formatTime(data.fulfillment.estimated_pickup_time),
            })}
          </h5>
          <div className="website-text text-center text-white break-word">{t('schedule.text')}</div>
        </Schedule>
      )}
      {data?.delivery?.runner_name ? (
        <div>
          <Divider className="website-text font-bold">{t('sections.driver_info')}</Divider>
          <p className="mb-0 mt-3">
            <CarrierIcon className="mr-2" />
            <b>{t('labels.driver_name')}</b>
            {': '}
            {data?.delivery?.runner_name}
          </p>
          {data?.delivery?.runner_phone_number ? (
            <p className="mb-0 mt-2">
              <PhoneIcon className="mr-2" />
              <b>{t('labels.driver_phone_number')}</b>
              {': '}
              {data?.delivery?.runner_phone_number}
            </p>
          ) : null}
        </div>
      ) : null}
      <Divider className="website-text font-bold">{t('sections.order_info')}</Divider>
      {data?.line_items?.map((item, idx) => (
        <OrderLineItem
          lineItem={item}
          isLastItem={data?.line_items?.length ? idx === data.line_items.length - 1 : false}
          key={idx}
        />
      ))}
      <Divider className="website-text font-bold">{t('sections.price_info')}</Divider>
      <div className="d-flex justify-content-between website-text mt-3">
        <div>{t('labels.original')}</div>
        <div>{formatCurrency(data?.sub_total, data?.currency)}</div>
      </div>
      {data?.method === ORDER_METHODS.delivery && data?.method_fee > 0 && (
        <div className="d-flex justify-content-between website-text mt-3">
          <div>{t('labels.delivery_fee')}</div>
          <div>{formatCurrency(data?.method_fee, data?.currency)}</div>
        </div>
      )}
      {data?.tip > 0 && (
        <div className="d-flex justify-content-between website-text mt-3">
          <div>{t('labels.tip')}</div>
          <div>{formatCurrency(data?.tip, data?.currency)}</div>
        </div>
      )}
      {data?.service_fee > 0 && (
        <div className="d-flex justify-content-between website-text mt-3">
          <div>{t('labels.service_fee')}</div>
          <div>{formatCurrency(data?.service_fee, data?.currency)}</div>
        </div>
      )}
      {data?.payment_method_upcharge_fee > 0 && (
        <div className="d-flex justify-content-between website-text mt-3">
          <div>{t(`labels.payment_method_upcharge_fee.${data.payment.payment_method_id}`)}</div>
          <div>{formatCurrency(data.payment_method_upcharge_fee)}</div>
        </div>
      )}
      {data?.total_discount > 0 && (
        <div className="d-flex justify-content-between website-text mt-3">
          <div>
            {t(`labels.promo_applied.${data?.coupon_type}`, { defaultValue: t('labels.promo_applied.default') })}
          </div>
          <div>
            -
            {formatCurrency(data?.total_discount, data?.currency)}
          </div>
        </div>
      )}
      {data?.additional_tax > 0 && (
        <div className="d-flex justify-content-between website-text mt-3">
          <div>{t('labels.tax')}</div>
          <div>{formatCurrency(data?.additional_tax, data?.currency)}</div>
        </div>
      )}
      <div className="d-flex justify-content-between website-text font-bold mt-3">
        <div>{t('labels.total')}</div>
        <div>{formatCurrency(data?.total, data?.currency)}</div>
      </div>
      {renderPaymentInfo()}
      {data.payment.adjustments.length > 0 && (
        <>
          <Divider className="website-text font-bold">{t('sections.adjustments')}</Divider>
          {data.payment.adjustments.map((item) => (
            <div>
              <div
                key={`refund-${item.id}`}
                className="d-flex justify-content-between website-text bold mt-3"
              >
                <div className="bold">{formatDateTime(item?.paid_at)}</div>
                <div className="bold">{formatCurrency(item.total)}</div>
              </div>
              <div className="website-text-mini mt-2 text-decoration-underline">{t(`adjustment_reasons.${item.adjustment_reason}`, item.adjustment_reason)}</div>
            </div>
          ))}
        </>
      )}
    </Drawer>
  );
}

const Divider = styled.div`
  ${tw`w-full mt-5 -mx-4 bg-gray-400 py-2 px-4`}
  width: 120%;
`;

const InfoContainer = tw.div`grid grid-cols-12 gap-6 w-full`;
const LeftInfoSection = tw.div`col-span-8 w-full break-words`;
const RightInfoSection = tw.div`col-span-4 w-full break-words`;

const Schedule = styled.div((props) => [
  tw`w-full mt-5 bg-blue py-4 px-8 relative`,
  'width: 120%;',
  'margin-left: -10%;',
  props?.isReminder ? tw`bg-orange` : tw`bg-blue`,
]);
