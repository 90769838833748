import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as Sentry from '@sentry/react';
import { configureStore } from '@reduxjs/toolkit';
import ActiveTabSlice from './slices/ActiveTabSlice';
import CurrentCompanySlice from './slices/CurrentCompanySlice';
import CurrentFranchiseSlice from './slices/CurrentFranchiseSlice';
import CurrentServiceProviderSlice from './slices/CurrentServiceProviderSlice';
import ExpandedProductsSlice from './slices/ExpandedProductsSlice';
import InitialDataSlice from './slices/InitialDataSlice';
import SideBarSlice from './slices/SideBarSlice';
import StorefrontSlice from './slices/StorefrontSlice';
import AppStatusSlice from './slices/AppStatusSlice';
import {
  menusApiSlice, categoriesApiSlice, modifierGroupsApiSlice, modifiersApiSlice, deliveriesApiSlice,
} from './api-slices';

const apiReducerPath = [
  menusApiSlice.reducerPath,
  categoriesApiSlice.reducerPath,
  modifierGroupsApiSlice.reducerPath,
  modifiersApiSlice.reducerPath,
  deliveriesApiSlice.reducerPath,
];

const persistConfig = {
  key: 'root',
  storage,
  blacklist: apiReducerPath,
};

export const reducersCombined = combineReducers({
  activeTab: ActiveTabSlice.reducer,
  currentCompany: CurrentCompanySlice.reducer,
  currentFranchise: CurrentFranchiseSlice.reducer,
  currentServiceProvider: CurrentServiceProviderSlice.reducer,
  expandedProducts: ExpandedProductsSlice.reducer,
  initialData: InitialDataSlice.reducer,
  sideBar: SideBarSlice.reducer,
  storefront: StorefrontSlice.reducer,
  appStatus: AppStatusSlice.reducer,
  [menusApiSlice.reducerPath]: menusApiSlice.reducer,
  [categoriesApiSlice.reducerPath]: categoriesApiSlice.reducer,
  [modifierGroupsApiSlice.reducerPath]: modifierGroupsApiSlice.reducer,
  [modifiersApiSlice.reducerPath]: modifiersApiSlice.reducer,
  [deliveriesApiSlice.reducerPath]: deliveriesApiSlice.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    return reducersCombined(undefined, action);
  }

  return reducersCombined(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const enhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    try {
      return action.type?.startsWith('initialData/')
        ? null
        : action;
    } catch (e) {
      return null;
    }
  },
  stateTransformer: (state) => ({
    ...state,
    initialData: null,
  }),
});

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      // TODO: remove once api manager is reworked
      ignoredActions: ['persist/PERSIST'],
      ignoredPaths: apiReducerPath,
    },
  }).concat(
    menusApiSlice.middleware,
    categoriesApiSlice.middleware,
    modifierGroupsApiSlice.middleware,
    modifiersApiSlice.middleware,
    deliveriesApiSlice.middleware,
  ),
  enhancers: [enhancer],
  devTools: import.meta.env.MODE !== 'production',
});

export const persistor = persistStore(store);
