import PopularItems from './PopularItems';
import QuickLinks from './QuickLinks';
import Company from './company';
import ServiceProvider from './service-provider';
import Statistics from './Statistics';

export default {
  PopularItems,
  QuickLinks,
  Company,
  ServiceProvider,
  Statistics,
};
