export default {
  title: 'Update dropoff time',
  labels: {
    dropoff_time: 'Dropoff time',
    confirmed: 'Confirmed time:',
    new_time: 'New time:',
  },
  buttons: {
    save: 'Save',
  },
  toasts: {
    failed_to_save: 'Failed to save changes',
  },
};
