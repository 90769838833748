export default {
  tabs: {
    overview: 'Overview',
    orders: 'Orders by source',
    google: 'Google',
    customers: 'Customers',
    general: 'General',
  },
  date_range: {
    this_month: 'This Month',
    last_month: 'Last Month',
    last_week: 'Last Week',
    this_week: 'This Week',
    this_year: 'This Year',
    last_year: 'Last Year',
    today: 'Today',
    yesterday: 'Yesterday',
    all: 'All',
  },
};
