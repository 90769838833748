import React from 'react';
import { ROLES, SCREENS } from '../services/exports/Constants';

const hasSelectedCompanyMiddleware = React.lazy(() => import('../navigation/middleware/HasSelectedCompanyGuard'));
const hasCompleteOnboardingMiddleware = React.lazy(() => import('../navigation/middleware/HasCompleteOnboardingGuard'));
const canSeeDashboard = React.lazy(() => import('../navigation/middleware/CanSeeDashboard'));

const DashboardRoutes = [
  {
    path: SCREENS.HOME,
    exact: true,
    component: React.lazy(() => import('../views/dashboard')),
    middleware: canSeeDashboard,
  },
  {
    path: SCREENS.GET_STARTED,
    component: React.lazy(() => import('../views/getting-started')),
  },
  {
    path: SCREENS.DASHBOARD,
    component: React.lazy(() => import('../views/dashboard')),
    middleware: canSeeDashboard,
  },
  {
    path: SCREENS.CUSTOMER_BASE,
    component: React.lazy(() => import('../views/customer-base')),
    roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
  },
  {
    path: SCREENS.COMPANIES,
    component: React.lazy(() => import('../views/companies')),
    roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
  },
  {
    path: SCREENS.STRIPE,
    component: React.lazy(() => import('../views/stripe')),
    roles: [ROLES.reseller, ROLES.onboarding_manager],
  },
  {
    path: SCREENS.EXTERNAL_DELIVERY_SUPPORT,
    component: React.lazy(() => import('../views/external-delivery-support')),
    roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
  },
  {
    path: SCREENS.GOING_LIVE_OVERVIEW,
    component: React.lazy(() => import('../views/going-live-overview')),
    roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
  },
  {
    path: SCREENS.GOING_LIVE_SETUP_PACKAGE_FULFILLMENTS,
    component: React.lazy(() => import('../views/going-live/setup-package-fulfillments')),
  },
  {
    path: SCREENS.FRANCHISES,
    component: React.lazy(() => import('../views/franchises')),
    roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
  },
  {
    path: SCREENS.STOREFRONT,
    component: React.lazy(() => import('../views/storefront')),
    middleware: hasSelectedCompanyMiddleware,
  },
  {
    path: SCREENS.MARKETING_OVERVIEW,
    component: React.lazy(() => import('../views/marketing/overview')),
  },
  {
    path: SCREENS.MARKETING_FOLLOWERS,
    component: React.lazy(() => import('../views/marketing/followers')),
    middleware: [hasSelectedCompanyMiddleware],
    exact: true,
  },
  {
    path: SCREENS.MARKETING_FOLLOWERS_IMPORT,
    component: React.lazy(() => import('../views/marketing/followers/import')),
    middleware: [hasSelectedCompanyMiddleware],
    exact: true,
  },
  {
    path: `${SCREENS.MARKETING_FOLLOWERS}/:id`,
    component: React.lazy(() => import('../views/marketing/followers/follower-details')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.MARKETING_EMAIL_MESSAGES,
    component: React.lazy(() => import('../views/marketing/email-messages')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: `${SCREENS.MARKETING_EMAIL_TEMPLATE}/:id`,
    component: React.lazy(() => import('../views/marketing/email-template')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.MARKETING_PROMO_CODES,
    component: React.lazy(() => import('../views/marketing/promo-codes')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.MARKETING_BOGO_CAMPAIGN,
    component: React.lazy(() => import('../views/marketing/bogo-campaign')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.MARKETING_GOOGLE_REVIEW_PROGRAM,
    component: React.lazy(() => import('../views/marketing/google-review-program')),
    middleware: [hasSelectedCompanyMiddleware],
    roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
  },
  {
    path: SCREENS.MARKETING_GOOGLE_BUSINESS,
    component: React.lazy(() => import('../views/marketing/google-business')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.MARKETING_LOYALTY_PROGRAM,
    component: React.lazy(() => import('../views/marketing/loyalty-program')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.MARKETING_FLYERS,
    component: React.lazy(() => import('../views/marketing/flyers')),
    middleware: [hasSelectedCompanyMiddleware],
    roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
  },
  {
    path: SCREENS.MARKETING_TRACKING_LINKS,
    component: React.lazy(() => import('../views/marketing/tracking-links')),
  },
  {
    path: SCREENS.MARKETING_GOOGLE_ADS,
    component: React.lazy(() => import('../views/marketing/google-ads')),
    exact: true,
  },
  {
    path: `${SCREENS.MARKETING_GOOGLE_ADS}/:id`,
    component: React.lazy(() => import('../views/marketing/google-ads/campaign-details')),
  },
  {
    path: SCREENS.MARKETING_WEBSITE_ANALYTICS,
    component: React.lazy(() => import('../views/marketing/website-analytics')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.RESERVATIONS_OVERVIEW,
    component: React.lazy(() => import('../views/reservations/overview')),
  },
  {
    path: SCREENS.RESERVATIONS_CREATE,
    component: React.lazy(() => import('../views/reservations/create-reservation')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.RESERVATIONS_SETTINGS,
    component: React.lazy(() => import('../views/reservations/settings/index')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.RESERVATIONS_BOOKING_AVAILABILITY,
    component: React.lazy(() => import('../views/reservations/booking-availability/index')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.SEO_OVERVIEW,
    component: React.lazy(() => import('../views/seo/overview')),
  },
  {
    path: SCREENS.GOOGLE_REVIEWS,
    component: React.lazy(() => import('../views/google-reviews/GoogleReviewsView')),
    roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
  },
  {
    path: SCREENS.SUBSCRIPTIONS,
    component: React.lazy(() => import('../views/subscriptions/subscriptions')),
    roles: ROLES.reseller,
  },
  {
    path: SCREENS.SUBSCRIPTION_PRODUCTS,
    component: React.lazy(() => import('../views/subscriptions/products')),
    roles: ROLES.reseller,
  },
  {
    path: SCREENS.SALES_ORDERS,
    component: React.lazy(() => import('../views/sales/orders')),
  },
  {
    path: SCREENS.SALES_RECEIPTS,
    component: React.lazy(() => import('../views/sales/receipts')),
    middleware: hasSelectedCompanyMiddleware,
  },
  {
    path: SCREENS.SALES_EXTERNAL_DELIVERIES,
    component: React.lazy(() => import('../views/sales/external-deliveries')),
    roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
  },
  {
    path: SCREENS.BILLING_MONTHLY_STATEMENTS,
    component: React.lazy(() => import('../views/billing/monthly-statements')),
    middleware: hasSelectedCompanyMiddleware,
    roles: [ROLES.reseller, ROLES.onboarding_manager],
  },
  {
    path: SCREENS.PAYOUTS,
    component: React.lazy(() => import('../views/billing/payouts')),
  },
  {
    path: SCREENS.BILLING_INVOICES,
    component: React.lazy(() => import('../views/billing/invoices')),
    middleware: [hasSelectedCompanyMiddleware, hasCompleteOnboardingMiddleware],
  },
  {
    path: SCREENS.BILLING_SUBSCRIPTION,
    component: React.lazy(() => import('../views/billing/subscription')),
    middleware: [hasSelectedCompanyMiddleware, hasCompleteOnboardingMiddleware],
  },
  {
    path: SCREENS.BILLING_INTERNAL_INVOICE_ITEMS,
    component: React.lazy(() => import('../views/billing/internal-invoice-items')),
  },
  {
    path: SCREENS.BILLING_INCOME_REPORTS,
    component: React.lazy(() => import('../views/billing/income-reports')),
  },
  {
    path: SCREENS.MENU,
    component: React.lazy(() => import('../views/menu/menu')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.MODIFIERS,
    component: React.lazy(() => import('../views/menu/modifiers')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.SMART_PRICING,
    component: React.lazy(() => import('../views/menu/smart-pricing')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.RECOMMENDED_PRODUCTS,
    component: React.lazy(() => import('../views/menu/recommended-products')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.SOLD_OUT_ITEMS,
    component: React.lazy(() => import('../views/menu/sold-out-items')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.MENU_IMPORT,
    component: React.lazy(() => import('../views/menu/import')),
    middleware: [hasSelectedCompanyMiddleware],
    roles: [ROLES.reseller, ROLES.sales_manager, ROLES.content_manager, ROLES.onboarding_manager],
  },
  {
    path: SCREENS.MY_STORE_SETTINGS,
    component: React.lazy(() => import('../views/my-store/settings')),
    middleware: [hasSelectedCompanyMiddleware],
    roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
  },
  {
    path: SCREENS.MY_STORE_GALLERY,
    component: React.lazy(() => import('../views/my-store/gallery')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.MY_STORE_BUSINESS_INFO,
    component: React.lazy(() => import('../views/my-store/business-info')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.MY_STORE_PERSONAL_INFO,
    component: React.lazy(() => import('../views/my-store/personal-info')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.MY_STORE_BANK_INFO,
    component: React.lazy(() => import('../views/my-store/bank-details')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.MY_STORE_TAX_INFO,
    component: React.lazy(() => import('../views/my-store/tax-details')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.MY_STORE_SHIPPING_INFO,
    component: React.lazy(() => import('../views/my-store/shipping-info')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.MY_STORE_GOOGLE_PROFILE,
    component: React.lazy(() => import('../views/my-store/google-profile')),
    middleware: [hasSelectedCompanyMiddleware],
    roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
  },
  {
    path: SCREENS.OPENING_HOURS,
    component: React.lazy(() => import('../views/opening-hours/opening-hours')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.LOCATIONS,
    component: React.lazy(() => import('../views/opening-hours/locations')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.TAKEOUT,
    component: React.lazy(() => import('../views/order-settings/takeout')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.PAYMENT_METHODS,
    component: React.lazy(() => import('../views/order-settings/payment-methods')),
    middleware: [hasSelectedCompanyMiddleware],
    roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
  },
  {
    path: SCREENS.TIP_SETTINGS,
    component: React.lazy(() => import('../views/order-settings/tips')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.TERMINAL,
    component: React.lazy(() => import('../views/order-settings/terminal')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.RECEIPT_SETTINGS,
    component: React.lazy(() => import('../views/order-settings/receipts')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.STAFF_CREDENTIALS,
    component: React.lazy(() => import('../views/order-settings/staff-credentials')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.Integrations,
    component: React.lazy(() => import('../views/order-settings/integrations')),
    middleware: [hasSelectedCompanyMiddleware],
    roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
  },
  {
    path: SCREENS.LOYALTY_DASHBOARD,
    component: React.lazy(() => import('../views/loyalty-tablet/dashboard')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.LOYALTY_TABLET,
    component: React.lazy(() => import('../views/loyalty-tablet/config')),
    middleware: [hasSelectedCompanyMiddleware],
    roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
  },
  {
    path: SCREENS.LOYALTY_TABLET_CREDENTIALS,
    component: React.lazy(() => import('../views/loyalty-tablet/credentials')),
    middleware: [hasSelectedCompanyMiddleware],
  },
  {
    path: SCREENS.BILLING_PAYMENT_INFO,
    component: React.lazy(() => import('../views/billing/payment-info')),
    middleware: [hasSelectedCompanyMiddleware, hasCompleteOnboardingMiddleware],
  },
  {
    path: SCREENS.CONTRACT_AGREEMENT,
    component: React.lazy(() => import('../views/contract/agreement')),
    middleware: [hasSelectedCompanyMiddleware, hasCompleteOnboardingMiddleware],
    exclude_roles: [ROLES.content_manager],
  },
  {
    path: SCREENS.CONTRACT_TEMPLATE,
    component: React.lazy(() => import('../views/contract/template')),
    roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
  },
  {
    path: SCREENS.TEAM_MEMBERS,
    component: React.lazy(() => import('../views/team/managers')),
    roles: [ROLES.reseller],
  },
  {
    path: SCREENS.TEAM_REPORTS,
    component: React.lazy(() => import('../views/team/reports')),
    roles: [ROLES.reseller],
  },
  {
    path: SCREENS.SALES_MANAGER_REPORTS,
    component: React.lazy(() => import('../components/team/reports/SignedLiveTab')),
    roles: [ROLES.sales_manager],
  },
  {
    path: SCREENS.PROFILE,
    component: React.lazy(() => import('../views/profile')),
    middleware: [hasSelectedCompanyMiddleware, hasCompleteOnboardingMiddleware],
  },
];

export default DashboardRoutes;
