import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../base-query';
import { serializeQueryArgs } from '../serialize-query-args';

export const modifierGroupsApiSlice = createApi({
  reducerPath: 'modifierGroupsApiSlice',
  baseQuery,
  endpoints: (builder) => ({
    getModifierGroups: builder.query({
      query: ({ apiManager, ...rest }) => apiManager.get(rest),
    }),
  }),
  serializeQueryArgs,
});

export const { useGetModifierGroupsQuery } = modifierGroupsApiSlice;
