export default {
  fields: {
    sales: {
      label: 'Sales',
    },
    average_order_value: {
      label: 'Average Order Value',
    },
    savings: {
      label: 'Savings on third party fees',
    },
    reservations: {
      label: 'Reservations',
    },
    sales_from_email: {
      label: 'Sales from Email',
    },
    sales_from_google_seo: {
      label: 'Sales from Google SEO',
    },
    sales_from_google_business_profile: {
      label: 'Sales from Google Business',
    },
    sales_from_google_ads: {
      label: 'Sales from Google Ads',
    },
    sales_from_flyers: {
      label: 'Sales from Flyers',
    },
    sales_from_social_media: {
      label: 'Sales from Social Media',
    },
    sales_from_facebook_ads: {
      label: 'Sales from Facebook Ads',
    },
    tips: {
      label: 'Tips',
    },
    new_customers: {
      label: 'New Customers',
    },
    returning_customers: {
      label: 'Returning Customers',
    },
    new_visitors: {
      label: 'New Visitors',
    },
    new_followers: {
      label: 'New Followers',
    },
    total_followers: {
      label: 'Total Followers',
    },
    delivered_messages: {
      label: 'Delivered Messages',
    },
    average_open_rate: {
      label: 'Average Open Rate',
    },
    average_click_rate: {
      label: 'Average Click Rate',
    },
    website_visitors_from_email: {
      label: 'Website Visitors from Email',
    },
    sales_from_google_business: {
      label: 'Sales from Google Business',
    },
    total_reservations: {
      label: 'Reservations',
    },
    website_visitors_from_google_seo: {
      label: 'Website Clicks from Google SEO',
    },
    website_clicks_from_google_business: {
      label: 'Website Clicks from Google Business',
    },
    website_visitors_from_google_ads: {
      label: 'Website Clicks from Google Ads',
    },
    website_visitors_from_social_media: {
      label: 'Website Clicks from Social Media',
    },
    calls_from_google_ads: {
      label: 'Calls from Google Ads',
    },
    directions_from_google_ads: {
      label: 'Directions from Google Ads',
    },
    google_seo_orders_count: {
      label: 'Google SEO',
    },
    google_business_orders_count: {
      label: 'Google Business',
    },
    google_ads_orders_count: {
      label: 'Google Ads',
    },
    facebook_ads_orders_count: {
      label: 'Facebook Ads',
    },
    social_media_orders_count: {
      label: 'Social Media',
    },
    flyer_orders_count: {
      label: 'Flyers',
    },
    email_orders_count: {
      label: 'Emails',
    },
    other_orders_count: {
      label: 'Others',
    },
    gmv: {
      label: 'GMV',
    },
    revenue: {
      label: 'Revenue',
    },
    revenue_subscription: {
      label: 'Revenue from Subscription',
    },
    revenue_service_fee: {
      label: 'Revenue from Service Fee',
    },
    revenue_service_charges: {
      label: 'Revenue from Service Charges',
    },
    processing_fees: {
      label: 'Processing Fees',
    },
    processing_fees_stripe: {
      label: 'Processing Fees (Stripe)',
    },
    processing_fees_paypal: {
      label: 'Processing Fees (PayPal)',
    },
    rejected_orders: {
      label: 'Rejected Orders',
    },
    rejected_orders_amount: {
      label: 'Rejected Orders Amount',
    },
    rejected_orders_count: {
      label: 'Rejected Orders Count',
    },
    rejected_orders_rate: {
      label: 'Rejected Orders Rate',
    },
    rejected_orders_rate_breakdown: {
      label: 'Rejected Orders Rate Breakdown',
    },
    orders: {
      label: 'Orders',
    },
    total_orders: {
      label: 'Total Orders',
    },
    pickup_orders_rate: {
      label: 'Pickup Orders Rate',
    },
    delivery_orders_rate: {
      label: 'Delivery Orders Rate',
    },
    other_orders_rate: {
      label: 'Other Orders Rate',
    },
    customers: {
      label: 'Total Customers',
    },
    customers_auth_state: {
      label: 'Customer Authentication State',
    },
    website_reservations: {
      label: 'Website Reservations',
    },
    google_reservations: {
      label: 'Google Reservations',
    },
    google_reviews: {
      label: 'Google Reviews',
    },
    loyalty_sign_ups: {
      label: 'Loyalty Sign Ups',
    },
    companies: {
      label: 'Companies',
    },
    live_restaurants: {
      label: 'Live Restaurants',
    },
    live_restaurants_percentage: {
      label: 'Live Restaurants Percentage',
    },
    crossed_sales_level_this_month: {
      label: 'Crossed Sales Level This Month',
    },
    crossed_sales_level_total: {
      label: 'Crossed Sales Level Total',
    },
    crossed_sales_level_percentage: {
      label: 'Crossed Sales Level Percentage',
    },
    has_delivery: {
      label: 'Own Delivery',
    },
    has_only_pickup: {
      label: 'Only Pickup',
    },
    has_storefront: {
      label: 'Has Storefront',
    },
    has_storefront_percentage: {
      label: 'Storefront Percentage',
    },
    use_external_delivery: {
      label: 'External Delivery',
    },
    crossed_700_sales: {
      label: 'Crossed 700,00 € sales',
    },
    crossed_monthly_sales_threshold_0: {
      label: 'Monthly Sales: 0 - 699',
    },
    crossed_monthly_sales_threshold_700: {
      label: 'Monthly Sales: 700+',
    },
    crossed_monthly_sales_threshold_2500: {
      label: 'Monthly Sales: 2500+',
    },
    crossed_monthly_sales_threshold_10000: {
      label: 'Monthly Sales: 10000+',
    },
    restaurant_partners: {
      label: 'Restaurant Partners',
    },
    registered_restaurants: {
      label: 'Registered Restaurants',
    },
    use_reservations: {
      label: 'Use Reservations',
    },
    number: {
      label: 'Number',
    },
    restaurants_percentage: {
      label: 'Restaurants Percentage',
    },
    crossed_sales_level: {
      label: 'Crossed Sales Level',
    },
    order_method: {
      label: 'Order Method',
    },
    own_delivery: {
      label: 'Own Delivery',
    },
    only_pickup: {
      label: 'Only Pickup',
    },
    storefront: {
      label: 'Storefront',
    },
    sales_tiers: {
      label: 'Sales Tiers',
    },
    first_delivery: {
      label: 'Uber',
    },
    wolt_drive: {
      label: 'Wolt',
    },
    delivery_quotes_count: {
      label: 'Delivery Quotes Count',
    },
    deliveries_count: {
      label: 'Deliveries Count',
    },
    delivery_conversion: {
      label: 'Delivery Conversion Rate',
    },
    delivery_quotes_provider_breakdown: {
      label: 'Delivery Quotes Provider Breakdown',
    },
    deliveries_provider_breakdown: {
      label: 'Deliveries Provider Breakdown',
    },
    total_delivered: {
      label: 'Delivered Messages',
    },
    website_visitors_from_google_business_profile: {
      label: 'Website clicks from Google Business',
    },
    google_business_impressions: {
      label: 'Impressions from Google Business',
    },
    google_business_website_clicks: {
      label: 'Website clicks from Google Business',
    },
    google_ads_impressions: {
      label: 'Impressions from Google Ads',
    },
    google_ads_clicks: {
      label: 'Clicks from Google Ads',
    },
    google_ads_phone_calls: {
      label: 'Calls from Google Ads',
    },
    google_ads_directions: {
      label: 'Directions from Google Ads',
    },
    website_visits: {
      label: 'Website Visits',
    },
    website_visitors: {
      label: 'Website Visitors',
    },
    companies_with_activated_reservations: {
      label: 'Restaurants with activated reservations',
    },
    online_orders_count: {
      label: 'Online Orders',
    },
    cash_orders_count: {
      label: 'Online Orders',
    },
    cash_sales: {
      label: 'Cash Sales',
    },
    online_sales: {
      label: 'Online Sales',
    },
  },
};
