import { CCol, CRow } from '@coreui/react';
import React, { useMemo } from 'react';
import { collect } from 'collect.js';
import tw from 'twin.macro';
import styled from 'styled-components';
import Modifier from './Modifier';
import useHelpers from '../../hooks/context/useHelpers';

export default function OrderLineItem({ lineItem, isLastItem }) {
  const { formatCurrency } = useHelpers();

  const modifiersByParentId = useMemo(() => collect(lineItem.modifiers).groupBy('parent_id'), [lineItem.modifiers]);
  const renderModifier = (modifier, isNested = false) => {
    const nestedModifiers = modifiersByParentId.get(modifier.id);

    return (
      <section key={modifier.id} className="pl-3">
        <Modifier
          name={modifier.modifier.name}
          quantity={modifier.quantity}
          price={modifier.package_price}
          isNested={isNested}
        />
        {nestedModifiers?.map((nestedModifier) => renderModifier(nestedModifier, true))}
      </section>
    );
  };

  return (
    <>
      <CRow className="mt-3">
        <CCol md={1} sm={1} xs={1}>
          <div className="website-text font-bold">
            {lineItem.quantity}
            x
          </div>
        </CCol>
        <CCol md={9} sm={8} xs={8}>
          <div className="website-text font-bold break-word">{lineItem.product.name}</div>
        </CCol>
        <CCol md={2} sm={3} xs={3}>
          <div className="text-right website-text">
            {formatCurrency(lineItem.item_price)}
          </div>
        </CCol>
      </CRow>
      {modifiersByParentId.has('') && (
        <CRow className="mt-1">
          <CCol xs={1} />
          <CCol xs={11}>
            {modifiersByParentId.get('').map((rootModifier) => renderModifier(rootModifier))}
          </CCol>
        </CRow>
      )}
      {lineItem.note && (
        <CRow className="mt-1">
          <CCol xs={1} />
          <CCol xs={11}>
            <div className="website-text italic">{lineItem.note}</div>
          </CCol>
        </CRow>
      )}
      {!isLastItem && <Divider />}
    </>
  );
}

const Divider = styled.hr`
  ${tw`w-full mt-2 mb-0 bg-silver`}
  height: 1px;
`;
