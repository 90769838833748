import { Trans, useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {
  Button, Switch, Table,
  Flex, Form, Input, Popconfirm, Select, Space, Spin, Radio, Card, Steps, Descriptions, Tooltip,
} from 'antd';
import {
  DeleteOutlined, InfoCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { collect } from 'collect.js';
import Ribbon from 'antd/es/badge/Ribbon';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import CardWrapper from '../../components/cards/CardWrapper';
import LoadingScreen from '../../components/loading/LoadingScreen';
import useForm from '../../hooks/utility/useForm';
import useApiClient from '../../hooks/api/useApiClient';
import useFeedbackLabel from '../../hooks/ui/useFeedbackLabel';
import {
  BusinessType,
  MENU_PROVIDERS,
  SCREENS,
} from '../../services/exports/Constants';
import PhoneNumberInput from '../../components/form/PhoneNumberInput';
import ReduxHooks from '../../store/ReduxHooks';
import { actionCreators } from '../../store/actions';
import useHelpers from '../../hooks/context/useHelpers';
import FranchiseSelect from '../../components/form/FranchiseSelect';
import useScreenType from '../../hooks/context/useScreenType';
import AddressHelpers from '../../services/helpers/AddressHelpers';
import BusinessProfileSelect from '../../components/form/BusinessProfileSelect';
import theme from '../../styles/Theme';
import AddressFields from '../../components/form/AddressFields';
import AddCompanyModal from '../../modals/company/AddCompanyModal';
import CompanyCard from '../../components/company/CompanyCard';

export const CompanyCreationModes = {
  demo: 'demo',
  full: 'full',
};

export const CompanyCreationSteps = {
  CompanyInfo: 'company_info',
  BusinessInfo: 'business_info',
  AdditionalLocations: 'additional_locations',
};

export const FranchiseOption = {
  CreateFranchise: 'create_franchise',
  UseExistingFranchise: 'use_existing_franchise',
};

export const BusinessProfileOption = {
  CreateBusinessProfile: 'create_business_profile',
  UseExistingBusinessProfile: 'use_existing_business_profile',
};

function Instruction({ children, className }) {
  return (
    <div className={classnames('tw-mb-small tw-border-solid tw-border-0 tw-border-l-[5px] tw-border-brand-warning tw-pl-xsmall', className)}>
      <p>
        {children}
      </p>
    </div>
  );
}

function CompaniesList({
  data, showAddButton, update, className,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Companies' });

  const [selectedCompanyIndex, setSelectedCompanyIndex] = useState(null);
  const [showCompanyModal, setShowCompanyModal] = useState(false);

  const toggleCompanyModal = (index = null) => {
    setSelectedCompanyIndex(index);
    setShowCompanyModal((current) => !current);
  };

  const onSaveNewCompany = (companyData) => {
    if (data.companies.length === 0) {
      update('business_profile', {
        ...data.business_profile,

        legal_name: companyData.name,
        display_name: companyData.name,

        legal_address: companyData.address,
        legal_city: companyData.city,
        legal_state: companyData.state,
        legal_country: companyData.country,
        legal_zip_code: companyData.zip_code,

        owner_email: null,
        owner_phone_number: null,
      });
    }

    if (selectedCompanyIndex === null) {
      return update('companies', [...data.companies, companyData]);
    }

    return update('companies', collect(data.companies).put(selectedCompanyIndex, companyData).toArray());
  };

  return (
    <>
      <h6 className={classnames('tw-underline tw-ml-small tw-mb-small', className)}>{t('form.restaurants.label')}</h6>
      <div
        className="tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4 tw-bg-brand-inkGrey-grey_0 tw-px-xsmall tw-py-small"
      >
        {data.franchise_option === FranchiseOption.UseExistingFranchise && (data.franchise?.companies ?? []).map((item) => <CompanyCard company={item} />)}
        {data.companies.map((item, index) => (
          <Ribbon text={<UploadOutlined />} color={theme.color.brand.warning} placement="start">
            <CompanyCard
              company={item}
              isDraft
              onEdit={() => toggleCompanyModal(index)}
              onDelete={() => update('companies', collect(data.companies).forget(index).values().toArray())}
            />
          </Ribbon>
        ))}
        {showAddButton && (
          <Card
            bordered={false}
            classNames={{ body: 'tw-h-full tw-flex tw-items-center tw-cursor-pointer !tw-py-large' }}
            onClick={() => toggleCompanyModal()}
          >
            <p className="tw-m-auto tw-text-center tw-font-semibold">{t('buttons.add_restaurant')}</p>
          </Card>
        )}
      </div>
      {showCompanyModal && (
        <AddCompanyModal
          company={selectedCompanyIndex !== null ? data.companies[selectedCompanyIndex] : null}
          onSave={onSaveNewCompany}
          onClose={toggleCompanyModal}
        />
      )}
    </>
  );
}

function CompanyInfoStep({
  data, update, mode, renderFeedbackLabel,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Companies' });

  return (
    <>
      <Form layout="vertical" className="tw-p-small tw-pb-none">
        {renderFeedbackLabel}
        <Form.Item label={<span className="tw-font-semibold tw-text-base">{t('form.franchise.label')}</span>}>
          <Instruction>{t('form.franchise.tooltip')}</Instruction>
          <Radio.Group value={data.franchise_option} onChange={(e) => update('franchise_option', e.target.value)}>
            <Space direction="vertical">
              <Radio value={FranchiseOption.CreateFranchise}>
                {t(`form.franchise.options.${FranchiseOption.CreateFranchise}.title`)}
                <Tooltip
                  title={t(`form.franchise.options.${FranchiseOption.CreateFranchise}.tooltip`)}
                  className="tw-ml-xmini"
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </Radio>
              <Radio value={FranchiseOption.UseExistingFranchise}>
                {t(`form.franchise.options.${FranchiseOption.UseExistingFranchise}.title`)}
                <Tooltip
                  title={t(`form.franchise.options.${FranchiseOption.UseExistingFranchise}.tooltip`)}
                  className="tw-ml-xmini"
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </Radio>
            </Space>
          </Radio.Group>
          {data.franchise_option === FranchiseOption.UseExistingFranchise && (
            <FranchiseSelect
              value={data.franchise?.id ? data.franchise : null}
              onChange={(value) => update('franchise', value)}
              className="tw-ml-[20px] tw-mt-mini tw-w-fit"
            />
          )}
        </Form.Item>
      </Form>
      <CompaniesList data={data} showAddButton={data.companies.length === 0 || mode === CompanyCreationModes.demo} update={update} />
    </>
  );
}

function BusinessInfoStep({ data, update, renderFeedbackLabel }) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Companies' });

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);

  const selectedBusinessProfileDescriptionItems = useMemo(
    () => (!data.business_profile.selected
      ? []
      : collect([
        { label: t('labels.business_type'), children: data.business_profile.selected.business_type },
        { label: t('labels.display_name'), children: data.business_profile.selected.display_name },
        { label: t('labels.legal_name'), children: data.business_profile.selected.legal_name },
      ]).when(
        Boolean(data.business_profile.selected.owner_full_name),
        (collection) => collection.push({ label: t('labels.owner_name'), children: data.business_profile.selected.owner_full_name }),
      ).merge([
        {
          label: t('labels.address'),
          children: AddressHelpers.format({
            street_name: data.business_profile.selected.legal_address,
            city: data.business_profile.selected.legal_city,
            state: data.business_profile.selected.legal_state,
            country: data.business_profile.selected.legal_country,
            zip_code: data.business_profile.selected.legal_zip_code,
          }),
        },
      ]).toArray()),
    [data.business_profile.selected],
  );

  return (
    <Form layout="vertical" className="tw-p-small">
      {renderFeedbackLabel}
      <Form.Item
        label={<span className="tw-font-semibold tw-text-base">{t('form.business_profile.label')}</span>}
        className="!tw-mb-none"
      >
        <Instruction><Trans t={t} components={{ br: <br /> }}>form.business_profile.tooltip</Trans></Instruction>
        <Radio.Group
          value={data.business_profile_option}
          onChange={(e) => update('business_profile_option', e.target.value)}
        >
          <Space direction="vertical">
            <Radio
              value={BusinessProfileOption.CreateBusinessProfile}
            >
              {t(`form.business_profile.options.${BusinessProfileOption.CreateBusinessProfile}`)}
            </Radio>
            <Radio
              value={BusinessProfileOption.UseExistingBusinessProfile}
            >
              {t(`form.business_profile.options.${BusinessProfileOption.UseExistingBusinessProfile}`)}
            </Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <div className="tw-ml-[20px] tw-mt-mini tw-mb-small">
        {data.business_profile_option === BusinessProfileOption.CreateBusinessProfile && (
          <>
            <Form.Item label={t('form.business_type.label')} required>
              <Select
                value={data.business_profile.business_type}
                onChange={(value) => update('business_profile.business_type', value)}
                placeholder={t('form.business_type.placeholder')}
                options={Object.values(BusinessType).map((item) => ({
                  label: t(`form.business_type.options.${item}`),
                  value: item,
                }))}
              />
            </Form.Item>
            <Form.Item label={t('form.display_name.label')} required>
              <Input
                value={data.business_profile.display_name}
                onChange={(e) => update('business_profile.display_name', e.target.value)}
                placeholder={t('form.display_name.placeholder')}
              />
            </Form.Item>
            <Form.Item label={t('form.legal_name.label')} required>
              <Input
                value={data.business_profile.legal_name}
                onChange={(e) => update('business_profile.legal_name', e.target.value)}
                placeholder={t('form.legal_name.placeholder')}
              />
            </Form.Item>
            <AddressFields
              useGrid
              prefix="legal"
              data={data.business_profile}
              onChange={(field, value) => update(`business_profile.${field}`, value)}
            />
            <Form.Item label={t('form.owner_phone_number.label')} required>
              <PhoneNumberInput
                defaultCountry={serviceProvider.country}
                value={data.business_profile.owner_phone_number ?? ''}
                onChange={(value) => update('business_profile.owner_phone_number', value)}
                international
                className="w-full font-sm relative"
                countryCallingCodeEditable={false}
              />
            </Form.Item>
            <Form.Item label={t('form.owner_email.label')} required>
              <Input
                value={data.business_profile.owner_email}
                autocomplete={null}
                onChange={(e) => update('business_profile.owner_email', e.target.value)}
                placeholder={t('form.owner_email.placeholder')}
                type="email"
              />
            </Form.Item>
            <Form.Item label={t('form.password.label')} required>
              <Input.Password
                autocomplete={null}
                onChange={(e) => update('business_profile.password', e.target.value)}
                placeholder={t('form.password.placeholder')}
                type="password"
              />
            </Form.Item>
          </>
        )}
        {data.business_profile_option === BusinessProfileOption.UseExistingBusinessProfile && (
          <>
            <BusinessProfileSelect
              value={data.business_profile.selected}
              onChange={(value) => update('business_profile.selected', value)}
              className="tw-w-fit"
            />
            {data.business_profile.selected && (
              <Descriptions
                className="tw-mt-small"
                items={selectedBusinessProfileDescriptionItems}
              />
            )}
          </>
        )}
      </div>
    </Form>
  );
}

function AdditionalLocationsStep({
  data, update,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Companies' });

  const company = data.companies[0]?.name ?? '';

  return (
    <>
      <Form layout="vertical" className="tw-p-small">
        <Form.Item
          label={<span className="tw-font-semibold tw-text-base">{t('form.additional_companies.label')}</span>}
          className="tw-mb-none"
        >
          <Instruction>
            <Trans t={t} values={{ company }} components={{ br: <br /> }}>form.additional_companies.tooltip</Trans>
          </Instruction>
        </Form.Item>
      </Form>
      <CompaniesList data={data} showAddButton update={update} />
    </>
  );
}

export default function CompaniesView() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Companies' });

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);

  const { CompaniesManager } = useApiClient();
  const { setFeedback, renderFeedbackLabel } = useFeedbackLabel();
  const { formatDateTime } = useHelpers();
  const { isDesktop } = useScreenType();
  const history = useHistory();

  const [mode, setMode] = useState(import.meta.env.PROD ? CompanyCreationModes.full : CompanyCreationModes.demo);
  const [currentStep, setCurrentStep] = useState(0);
  const isFinalStep = mode === CompanyCreationModes.demo || currentStep === 2;

  const { data, update } = useForm({
    franchise_option: null,
    business_profile_option: null,

    franchise: {
      id: null,
      companies: [],
    },
    business_profile: {
      selected: null,
      business_type: null,
      legal_name: null,
      display_name: null,

      legal_address: null,
      legal_city: null,
      legal_state: null,
      legal_country: null,
      legal_zip_code: null,

      owner_email: null,
      owner_phone_number: null,
      password: null,
    },
    companies: [],
  });

  const isStepComplete = useMemo(
    () => {
      const isRestaurantInfoStepComplete = data.companies.length > 0
        && Boolean(data.franchise_option)
        && (data.franchise_option === FranchiseOption.CreateFranchise || data.franchise.id !== null);

      const isBusinessInfoStepComplete = (data.business_profile_option === BusinessProfileOption.UseExistingBusinessProfile && Boolean(data.business_profile.selected))
        || (
          data.business_profile_option === BusinessProfileOption.CreateBusinessProfile
          && Boolean(data.business_profile.business_type)
          && Boolean(data.business_profile.legal_name)
          && Boolean(data.business_profile.display_name)
          && Boolean(data.business_profile.legal_address)
          && Boolean(data.business_profile.legal_city)
          && Boolean(data.business_profile.legal_state)
          && Boolean(data.business_profile.legal_country)
          && Boolean(data.business_profile.legal_zip_code)
          && Boolean(data.business_profile.owner_email)
          && Boolean(data.business_profile.owner_phone_number)
          && Boolean(data.business_profile.password)
        );

      return ({
        0: isRestaurantInfoStepComplete,
        1: isBusinessInfoStepComplete,
        2: isRestaurantInfoStepComplete && isBusinessInfoStepComplete,
      })[currentStep];
    },
    [data, currentStep],
  );

  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(null);

  useEffect(
    () => loadCompanies(),
    [serviceProvider?.id],
  );

  const createCompanies = async () => {
    setCreating(true);
    const { success, data: responseData } = await CompaniesManager.create(
      collect({
        is_demo: mode === CompanyCreationModes.demo,
        franchise_id: data.franchise_option === FranchiseOption.UseExistingFranchise ? data.franchise.id : null,
        companies: collect(data.companies).map((item) => ({
          ...item,
          google_places_id: item.google_place?.place_id ?? null,
          menu_provider_id: item.menu_provider === MENU_PROVIDERS.foodamigos ? `${item.menu_provider_id?.id}` : item.menu_provider_id,
        })),
      }).when(
        data.business_profile_option === BusinessProfileOption.UseExistingBusinessProfile,
        (collection) => (
          mode === CompanyCreationModes.demo
            ? collection
            : collection.put('business_profile_id', data.business_profile.selected.id)
        ),
        (collection) => (
          mode === CompanyCreationModes.demo
            ? collection
            : collection.put('business_profile', data.business_profile)
        ),
      ).all(),
    );
    setCreating(false);

    if (!success) {
      toast.error(t('toasts.failed_to_create_company'));

      return setFeedback({
        message: responseData?.message,
        type: 'error',
        errors: responseData?.errors,
      });
    }

    toast.success(t('toasts.company_created'));

    ReduxHooks.dispatch(
      actionCreators.currentCompany.set(responseData),
    );

    setCompanies((current) => [
      responseData,
      ...current,
    ]);

    mode !== CompanyCreationModes.demo && history.push(SCREENS.GET_STARTED);
  };

  const loadCompanies = async (page) => {
    setLoading(true);
    const { success, data: responseData } = await CompaniesManager.get({
      page,
    });
    setLoading(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_load_recently_created_companies'));
    }

    setCompanies(responseData);
  };

  const deleteCompany = async (id) => {
    if (import.meta.env.PROD) {
      return;
    }

    setDeleting(id);
    const { success } = await CompaniesManager.delete(id);
    setDeleting(null);

    if (!success) {
      return toast.error(t('toasts.failed_to_delete_demo'));
    }

    setCompanies((current) => ({
      ...current,
      data: collect(current.data).where('id', '!=', id).toArray(),
    }));

    return toast.success(t('toasts.demo_got_deleted'));
  };

  const onProceed = () => {
    if (isFinalStep) {
      return createCompanies();
    }

    setCurrentStep((current) => current + 1);
  };

  const renderContent = () => (
    <div>
      <h2>{t('title')}</h2>
      <CardWrapper
        containerClassName="tw-mt-small"
        className="!tw-p-none"
        header={(
          <div className="w-full tw-flex tw-items-center tw-justify-between">
            <h6>{t(`sections.new_company.titles.${mode}`)}</h6>
            {!import.meta.env.PROD && (
              <Flex>
                <Switch
                  checked={mode === CompanyCreationModes.demo}
                  onChange={(checked) => setMode(checked ? CompanyCreationModes.demo : CompanyCreationModes.full)}
                  id="is-demo"
                />
                <label
                  htmlFor="is-demo"
                  className="tw-ml-mini tw-my-auto"
                >
                  {mode === CompanyCreationModes.demo ? t('labels.create_demo') : t('labels.create_company')}
                </label>
              </Flex>
            )}
          </div>
        )}
        footer={(
          <Flex justify="end" gap="8px">
            {mode !== CompanyCreationModes.demo && currentStep > 0 && (
              <Button
                onClick={() => setCurrentStep((current) => current - 1)}
              >
                {t('buttons.back')}
              </Button>
            )}
            <Button
              type="primary"
              loading={creating}
              disabled={!isStepComplete}
              onClick={onProceed}
            >
              {t(isFinalStep ? 'buttons.create' : 'buttons.continue')}
            </Button>
          </Flex>
        )}
      >
        {mode !== CompanyCreationModes.demo && (
          <Steps
            className="tw-my-small tw-px-small tw-max-w-[70%] tw-mx-auto"
            current={currentStep}
            onChange={setCurrentStep}
            size={isDesktop ? 'default' : 'small'}
            items={[
              {
                title: t(`sections.new_company.steps.${CompanyCreationSteps.CompanyInfo}`),
              },
              {
                title: t(`sections.new_company.steps.${CompanyCreationSteps.BusinessInfo}`),
              },
              {
                title: t(`sections.new_company.steps.${CompanyCreationSteps.AdditionalLocations}`),
              },
            ]}
          />
        )}
        {currentStep === 0 && (
          <CompanyInfoStep
            data={data}
            mode={mode}
            update={update}
            setCurrentStep={setCurrentStep}
            renderFeedbackLabel={renderFeedbackLabel}
          />
        )}
        {currentStep === 1 && <BusinessInfoStep data={data} update={update} renderFeedbackLabel={renderFeedbackLabel} />}
        {currentStep === 2 && <AdditionalLocationsStep data={data} update={update} />}
      </CardWrapper>
      <div className="tw-mt-large">
        <h2>{t('sections.recently_created')}</h2>
        <div className="tw-mt-medium tw-bg-background-inkWhite-white_0 tw-shadow tw-rounded-lg">
          <Table
            dataSource={companies.data}
            columns={[
              {
                key: 'name',
                title: t('fields.name'),
                dataIndex: 'name',
              },
              {
                key: 'created_at',
                title: t('fields.created_at'),
                render: (_, item) => formatDateTime(item.created_at),
              },
              {
                key: 'actions',
                title: t('fields.actions'),
                render: (_, item) => (
                  <Space size="middle">
                    {!import.meta.env.PROD && (
                      <Popconfirm
                        title={t('popups.delete_demo.title')}
                        icon={<DeleteOutlined />}
                        onConfirm={() => deleteCompany(item.id)}
                        okButtonProps={{
                          danger: true,
                          loading: deleting === item.id,
                        }}
                      >
                        <a className="!tw-text-[#1677ff]">{deleting === item.id ? <Spin className="tw-mx-auto" /> : t('buttons.delete')}</a>
                      </Popconfirm>
                    )}
                  </Space>
                ),
              },
            ]}
            pagination={{
              current: companies.current_page,
              total: companies.total,
              pageSize: companies.per_page,
              onChange: (page) => loadCompanies(page),
            }}
            loading={loading}
            className="rounded-none table-responsive position-initial"
            rowKey={(item) => item.id}
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      {loading && <LoadingScreen />}
      {!loading && renderContent()}
    </>
  );
}
