import { GOOGLE_ADS_CAMPAIGN_STATUS } from '../../../../../../services/exports/Constants';

export default {
  title: 'Google Ads',
  search: {
    placeholder: 'Search by ID, Name, or Company',
  },
  columns: {
    name: 'Name',
    company: 'Company',
    connected: 'Connected',
    action: 'Action',
    details: 'Details',
    address: 'Address',
    status: 'Status',
  },
  status: {
    [GOOGLE_ADS_CAMPAIGN_STATUS.UNSPECIFIED]: 'Not Specified',
    [GOOGLE_ADS_CAMPAIGN_STATUS.ENABLED]: 'Active',
    [GOOGLE_ADS_CAMPAIGN_STATUS.PAUSED]: 'Paused',
    [GOOGLE_ADS_CAMPAIGN_STATUS.UNKNOWN]: 'Unknown',
    [GOOGLE_ADS_CAMPAIGN_STATUS.REMOVED]: 'Removed',
  },
};
