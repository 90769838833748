import React from 'react';
import useHelpers from '../../hooks/context/useHelpers';

export default function Modifier({
  name, price, quantity, isNested,
}) {
  const { formatCurrency } = useHelpers();
  const modifierIcon = isNested ? '+' : '•';
  const quantityTitle = ` ${quantity}x `;

  return (
    <div className="d-flex flex-row justify-content-between">
      <div>
        {modifierIcon}
        <span className="font-bold">{quantityTitle}</span>
        {name}
      </div>
      <div>
        {formatCurrency(price)}
      </div>
    </div>
  );
}
