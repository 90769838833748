import React, { useRef } from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader, CCardTitle,
  CFormGroup,
  CFormText,
  CInput, CInputRadio,
  CLabel,
  CSelect,
  CSwitch,
  CTabPane,
} from '@coreui/react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import CustomButton from '../CustomButton';
import {
  DELIVERY_BRAND_BY_PROVIDER,
  DeliveryMode,
  LAST_ORDER_GAP_OPTIONS,
  ORDER_METHODS,
  PERMISSIONS,
} from '../../services/exports/Constants';
import DeliveryZones from './delivery/DeliveryZones';
import usePermissions from '../../hooks/auth/usePermissions';
import TextArea from '../form/TextArea';
import DeliveryProvidersList from './DeliveryProvidersList';

const HIDDEN_PROVIDERS = ['standalone', 'first_delivery'];

export default function DeliveryTab({
  data,
  toggleMethodVisible,
  validator,
  feedback,
  loading,
  onChange,
  onSave,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Takeout:DeliveryTab' });

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);
  const regionalDeliveryProviders = serviceProvider.regional_config.supported_delivery_providers?.filter((item) => !HIDDEN_PROVIDERS.includes(item)) ?? [];
  const hasDeliveryProvidersList = regionalDeliveryProviders.length > 1;
  const { can } = usePermissions();

  const deliveryZonesRef = useRef(null);

  const _onSave = async () => {
    await onSave();
    await deliveryZonesRef.current?.requestDeliveryZones();
  };

  return (
    <CTabPane data-tab="delivery" className="mt-4">
      <CCard>
        <CCardHeader>{t('sections.state.title')}</CCardHeader>
        <CCardBody>
          <CFormGroup className="d-flex">
            <CSwitch
              id="delivery-visible"
              shape="pill"
              color="primary"
              size="lg"
              variant="3d"
              checked={!data.hidden_order_methods.includes(ORDER_METHODS.delivery)}
              onChange={toggleMethodVisible}
            />
            <CLabel
              htmlFor="delivery-visible"
              className="my-auto ml-3 cursor-pointer"
            >
              {t('sections.state.fields.delivery_visible.label')}
            </CLabel>
          </CFormGroup>
          <CFormGroup className="d-flex">
            <CSwitch
              id="has-delivery"
              shape="pill"
              color="primary"
              size="lg"
              variant="3d"
              checked={data?.has_delivery}
              onChange={(e) => onChange('has_delivery', e.target.checked)}
            />
            <CLabel
              htmlFor="has-delivery"
              className="my-auto ml-3 cursor-pointer"
            >
              {t('sections.state.fields.has_delivery.label')}
            </CLabel>
            <CFormText className="help-block">{feedback?.errors?.has_delivery}</CFormText>
          </CFormGroup>
          <CFormGroup className="d-flex">
            <CSwitch
              id="delivery-enabled"
              shape="pill"
              color="primary"
              size="lg"
              variant="3d"
              checked={data?.delivery_enabled}
              onChange={(e) => onChange('delivery_enabled', e.target.checked)}
            />
            <CLabel
              htmlFor="delivery-enabled"
              className="my-auto ml-3 cursor-pointer"
            >
              {t('sections.state.fields.delivery_enabled.label')}
            </CLabel>
            <CFormText className="help-block">{feedback?.errors?.delivery_enabled}</CFormText>
          </CFormGroup>
          <CustomButton
            loading={loading}
            disabled={loading}
            onClick={_onSave}
            buttonType="primary"
            className="my-3"
            title={t('buttons.save')}
          />
        </CCardBody>
      </CCard>
      {can(PERMISSIONS.manage_external_delivery) && serviceProvider.regional_config.supported_delivery_providers.length > 1 && (
        <CCard>
          <CCardBody>
            <CCardTitle>{t('sections.delivery_mode.title')}</CCardTitle>
            <CFormGroup className="mt-3">
              {Object.values(DeliveryMode).map((item) => (
                <div className="mb-2 d-flex position-relative">
                  <CInputRadio
                    id={`has-${item}-delivery`}
                    checked={data.delivery_mode === item}
                    onChange={(e) => e.target.checked && onChange('delivery_mode', item)}
                    className="ml-0 position-absolute top-1/2 -translate-y-1/2 cursor-pointer"
                  />
                  <CLabel
                    htmlFor={`has-${item}-delivery`}
                    className="ml-4 my-auto cursor-pointer"
                  >
                    {t(
                      `sections.delivery_mode.fields.delivery_mode.options.${item}`,
                      { provider: DELIVERY_BRAND_BY_PROVIDER[data.delivery_providers[0]] },
                    )}
                  </CLabel>
                </div>
              ))}
            </CFormGroup>
            {hasDeliveryProvidersList && (
            <DeliveryProvidersList
              data={data.delivery_providers}
              regionalDeliveryProviders={regionalDeliveryProviders}
              onChange={(data) => onChange('delivery_providers', data)}
            />
            )}
            {data.delivery_mode === DeliveryMode.External && (
              <CFormGroup>
                <CLabel htmlFor="external-delivery-pickup-note">
                  {t('sections.delivery_mode.fields.external_delivery_pickup_note.label')}
                </CLabel>
                <TextArea
                  value={data.external_delivery_pickup_note}
                  onChange={(value) => onChange('external_delivery_pickup_note', value)}
                  limit={200}
                  placeholder={t('sections.delivery_mode.fields.external_delivery_pickup_note.placeholder')}
                />
              </CFormGroup>
            )}
            <CustomButton
              title={t('buttons.save')}
              loading={loading}
              onClick={_onSave}
              className="my-3"
            />
          </CCardBody>
        </CCard>
      )}
      <CCard>
        <CCardHeader>{t('sections.delivery_info.title')}</CCardHeader>
        <CCardBody>
          <CFormGroup className="mt-2">
            <CLabel htmlFor="average-order-delivery-time-min">{t('sections.delivery_info.fields.average_order_delivery_time_min.label')}</CLabel>
            <div className="d-flex">
              <CInput
                id="average-order-delivery-time-min"
                placeholder={t('sections.delivery_info.fields.average_order_delivery_time_min.placeholder')}
                type="number"
                value={data?.average_order_delivery_time_min ?? ''}
                onChange={(e) => onChange('average_order_delivery_time_min', e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
              <CLabel
                htmlFor="average-order-delivery-time-max"
                className="my-auto mx-2"
              >
                {t('sections.delivery_info.fields.average_order_delivery_time_max.label')}
              </CLabel>
              <CInput
                id="average-order-delivery-time-max"
                placeholder={t('sections.delivery_info.fields.average_order_delivery_time_max.placeholder')}
                type="number"
                value={data?.average_order_delivery_time_max ?? ''}
                onChange={(e) => onChange('average_order_delivery_time_max', e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
            </div>
            <CFormText className="help-block">
              {data?.has_delivery && validator.message(t('sections.delivery_info.fields.average_order_delivery_time_min.label'), data?.average_order_delivery_time_min, 'required')}
              {feedback?.errors?.average_order_delivery_time_min}
              {' '}
              {feedback?.errors?.average_order_delivery_time_max}
            </CFormText>
          </CFormGroup>
          <CFormGroup>
            <CLabel htmlFor="last-order-gap-delivery">
              <Trans
                t={t}
              >
                sections.scheduled_orders.fields.last_order_gap_delivery.label
              </Trans>
            </CLabel>
            <CSelect
              id="last-order-gap-delivery"
              value={data?.last_order_gap_delivery ?? ''}
              onChange={(e) => onChange('last_order_gap_delivery', e.target.value)}
            >
              {LAST_ORDER_GAP_OPTIONS.map((item) => (
                <option
                  value={item}
                  key={`last-scheduled-ahead-order-delivery-${item}`}
                >
                  {t('sections.scheduled_orders.fields.last_order_gap_delivery.option', { value: item })}
                </option>
              ))}
            </CSelect>
          </CFormGroup>
          <CustomButton
            loading={loading}
            disabled={loading}
            onClick={_onSave}
            title={t('buttons.save')}
            className="my-3"
          />
        </CCardBody>
      </CCard>
      <CCard className="mt-4">
        <CCardHeader>
          {t('sections.scheduled_orders.title')}
        </CCardHeader>
        <CCardBody>
          <CFormGroup>
            <CSwitch
              id="has-scheduled-orders-delivery"
              shape="pill"
              color="primary"
              size="lg"
              variant="3d"
              checked={data?.has_scheduled_orders}
              onChange={(e) => {
                if (!e.target.checked && !data?.has_asap_orders) {
                  return toast.error(t('toasts.at_least_one_order_method_required'));
                }

                return onChange('has_scheduled_orders', e.target.checked);
              }}
            />
            <CLabel
              className="ml-3 align-bottom cursor-pointer"
              htmlFor="has-scheduled-orders-delivery"
            >
              {t('sections.scheduled_orders.fields.has_scheduled_orders.label')}
            </CLabel>
            <CFormText className="help-block">{feedback?.errors?.has_scheduled_orders}</CFormText>
          </CFormGroup>
          {data?.has_scheduled_orders && (
            <CFormGroup>
              <CLabel htmlFor="min-schedule-ahead-time-delivery">
                <Trans
                  t={t}
                >
                  sections.scheduled_orders.fields.min_schedule_ahead_time_delivery.label
                </Trans>
              </CLabel>
              <CInput
                id="min-schedule-ahead-time-delivery"
                placeholder={t('sections.scheduled_orders.fields.min_schedule_ahead_time_delivery.placeholder')}
                type="number"
                value={data?.min_schedule_ahead_time_delivery ?? ''}
                onChange={(e) => onChange('min_schedule_ahead_time_delivery', e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
            </CFormGroup>
          )}
          <CustomButton
            loading={loading}
            disabled={loading}
            onClick={_onSave}
            buttonType="primary"
            className="my-3"
            title={t('buttons.save')}
          />
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader>{t('sections.delivery_zones.title')}</CCardHeader>
        <CCardBody>
          <DeliveryZones
            ref={deliveryZonesRef}
            deliveryMode={data.delivery_mode}
          />
        </CCardBody>
      </CCard>
    </CTabPane>
  );
}
