import React from 'react';
import { useTranslation } from 'react-i18next';
import { CopyOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { toast } from 'react-toastify';

export default function ClickToCopy({ value, children, textClassName }) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:ClickToCopy' });

  const copy = () => {
    navigator.clipboard.writeText(value ?? children);

    toast.success(t('success'));
  };

  return (
    <span className={textClassName}>
      {children}
      <Tooltip title={t('tooltip_title')} placement="right">
        <CopyOutlined className="tw-ml-xTiny tw-cursor-pointer text-black" onClick={copy} />
      </Tooltip>
    </span>
  );
}
